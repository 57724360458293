<template>
  <div class="full">

    <div class="section section-custom-13">
      <div class="containers">
        <div class="headline">
            <h1>404 Error Page</h1>
            <h4 class="mt-4">Sorry we couldn't find that page</h4>
            <p>Try searching or go to Vayalagro's Home</p>
            <button class="mt-4 btns-soon my-2 px-4 py-2"><a href="/">Go Home</a></button> 
        </div>
      </div> 
    </div>
   
    <!-- Add your content here -->
  </div>
</template>
  
<script>
  import { en, ta } from "@/translations";
  
  export default {
     props: {
      selectedLanguage: String,
    },
      computed: {
      translations() {
        return this.selectedLanguage === "en" ? en : ta;
      },
      },
    name: "ErrorPage",
    metaInfo() {
      return {
        title: this.$route.meta.title,
        meta: this.$route.meta.metaTags
      };
    }
    
  };
  
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");
.fullshare .lefti img{
  width:100px;
  position: absolute;
  left: 0px;
  z-index: 1;
  margin-top: 50px;
  }
  .fullshare .leftr img{
    width: 100px;
    position: absolute;
    right: 0px;
    z-index: 0;
    margin-top: 50px;

    }
 
    .fullshare .leftc img{
      width:350px;
      position: absolute;
      left: 40%;
      z-index: -1;

      }
.name {
  font-family: "Satisfy";
  font-style: normal;
  position: relative;
  color: #4e7300;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name span {
  transform: rotate(-deg);
  position: absolute;
  z-index: 1;
  margin: -30px;
}
.headline {
  text-align: center;
  margin-bottom: 40px;
}
.headline p {
  font-size: 16px;
}
.containers {
  padding-top: 10vh;
}
.breadcrumb {
  display: flex;
  justify-content: center;
}

.breadcrumb:not(:first-child) {
  margin-top: 30px;
}
.breadcrumb_link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--color-text);
  text-decoration: none;
}
.breadcrumb_link:not(:first-child) {
  margin-left: 8px;
}
.breadcrumb_link:hover {
  color: rgb(68, 142, 68);
}
.breadcrumb_link_icon {
  flex: 0 0 auto;
  margin-left: 8px;
  font-size: 13px;
}
.breadcrumb_active {
  margin-left: 8px;
  white-space: nowrap;
  color: rgb(68, 142, 68);
}
.mission h3 {
  text-align: center;
  color: rgb(68, 142, 68);
}
.section2 {
  padding: 30px 10px;
  background-color: rgb(245, 245, 245);
}
.mission {
  margin: 20px 0px 20px 0px;
}
.mission h4 {
  color: #025721;
  font-size: 20px;
  padding: 0px 0px 10px 0px;
}
.mission p {
  font-size: 16px;
  color: rgb(68, 142, 68);
  text-align: center;
}

.mission2 {
  padding: 0px 0px;
}
.mission2 h6 {
  font-size: 16px;
  color: rgba(70, 67, 67, 0.8);
}
.section:not(:first-child) {
  opacity: 0;
  transition: opacity 1s ease;
}

.section:first-child {
  opacity: 1;
}
@media screen and (max-device-width: 992px) {
  .mission2 {
    padding: 0px 20px;
  }
}
@media screen and (max-device-width: 768px) {
  .mission2 {
    padding: 0px 10px;
  }
  .mission {
    display: block;
  }
  .mission h4 {
    color: #025721;
    font-size: 16px;
    padding: 20px 0px 0px 0px;
  }
  .mission p {
    font-size: 14px;
    color: rgb(68, 142, 68);
  }
}


.section3 {
  margin: 0px 20px;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mass {
  flex-basis: calc(33.33% - 20px);
  margin: 20px 0px;
}

img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 6px;
}
@media screen and (min-width: 1800px) {
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
  }
}

@media (max-width: 768px) {
  .mass {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .mass {
    flex-basis: 100%;
  }
}
@media (min-width: 1800px) {
  .full{
    margin: 0 10%;
  }
  }
  .btns-soon{
  background: #71A600;
  color: white;
  font-family: 'satisfy';
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: none;
  border-radius: 6px;
}

.btns-soon a{
  text-decoration: none;
  color: white;
}
</style>
  