

<template>
  <div class="fulls m-0 pb-5 ">
    <div class="wheet m-0">
      <img src="../../assets/img/newimg/image 9.webp" loading="lazy" alt="new-img-9" />
    </div>
    <div class="hd mx-md-5 pb-5 front">
      <div class="signup mx-5 pt-5">
        <div class="m-0 p-0">
          <div class="exemple exemple-z-index-1">
          </div>
          <div class="exemple exemple-z-index-2">{{ translations.signup }}</div>
        </div>
        <div>
          <h4 class="subname"  :class="{ 'tamil-font': selectedLanguage === 'ta', 'normal-font': selectedLanguage !== 'ta' }">{{translations.signuptag}}</h4>
        </div>
      </div>

      <div class="sendmail mx-md-5">
        <div class="mess">
          <!-- <div class="col-2"></div> -->
          <div class="container my-3">
            <div class="div my-3">
              <form @submit.prevent="sendEmail" class="newsletter3_form d-flex">
                <div>
                  <input type="email" name="email" v-model="email" class="newsletter3_form_input py-2 px-3"
                    :placeholder="translations.emailPlaceholder" required />
                </div>
                <button class="newsletter3_form_button py- d-flex" type="submit" value="Send">
                  <p class="w-100 py- my-1 px- send">
                    <span class="pe-1 dlt"> Send Mail </span>
                   
                  </p>
                </button>
              </form>
            </div>
            <div v-if="showSuccessMessage" class="success-message">
              {{ translations.successMessage }}
            </div>
          </div>
          <!-- <div class="col-2"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import { en, ta } from "@/translations";

const EMAILJS_USER_ID = "8BXWKT4hWl3AcLvXv";
export default {
  props: ["selectedLanguage"],
  data() {
    return {
      email: "",
      
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  methods: {
    sendEmail() {
      const templateParams = {
        from_email: this.email,
      };

      emailjs
        .send(
          "service_3vjjr4w",
          "template_a1pdkds",
          templateParams,
          EMAILJS_USER_ID 
        )
        .then((response) => {
           console.log("Email sent successfully!", response);
          this.showSuccessMessage = true;
          this.resetForm();
          setTimeout(() => {
            this.showSuccessMessage = false; 
          }, 3000); 
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    },
    resetForm() {
      this.email = "";
    },
  },
};
</script>



<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");

.fulls {
  width: 100%;
  font-family: "Raleway", sans-serif;
  background-image: url("../../assets/img/newimg/farm-02\ 1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fcfaf9;
  position: relative;
}
.exemple {
  position: relative;
  top: 0px;
  left: 0px;
}

.exemple-z-index-1 {
  z-index: 10;
  left: -30px;
  top: 60px;
}

.exemple-z-index-2 {
  z-index: 1;
  top: -0px;
  left: 10px;
  color: #4e7300;
  font-family: "Satisfy";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subname {
  color: #595959;
  font-family: "Mukta";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.normal-font {
  font-size: 30px;
}

.tamil-font {
  font-size: 20px; 
}

.newsletter3_form {
  width: 60%;
}

.newsletter3_form div {
  width: 100%;
}

.newsletter3_form div input {
  border-radius: 30px 0px 0px 30px;
  border: 1px solid #4e7300;
  border-right: none;
  width: 100%;
  height: 60px;
}

.newsletter3_form div input:focus {
  outline: none;
}

.newsletter3_form button {
  border-radius: 0px 30px 30px 0px;
  border: 1px solid #4e7300;
  border-left: none;
  background: #fff;
  color: #fcfaf9;
}

.newsletter3_form div input::placeholder {
  font-size: 14px;
}

.newsletter3_form button {
  width: 200px;
}

.newsletter3_form button p {
  background: #71a600;
  border-radius: 30px;
  font-size: 14px;
  width: 100%;
}

.send {
  padding: 13.5px 0px;
}

@media screen and (max-device-width: 993px) {
  .newsletter3_form {
    width: 90%;
  }
}

@media screen and (max-device-width: 992px) {

}

@media screen and (max-device-width: 660px) {
  .newsletter3_form {
    width: 90%;
  }

  .subname {
    font-size: 20px;
  }

  .newsletter3_form div input {
    height: 45px;
  }

  .send {
    padding: 6px 0px;
  }
}

.signup {
  z-index: 2;
}
.front{
position: relative;
}
.wheet {
  z-index: 0;
  position: absolute;
  right: 0;
}

.wheet img {
  height: 80%;
}
</style>
