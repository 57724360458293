<template>
 <div class="pagelink d-flex py-5">
      <div class="container-fluid pt-5 text-center">
        <h1 class="text-uppercase" v-if="this.$route.params.product">
         {{ this.$route.params.product.replace(/-/g, ' ') }}
        </h1>
        <h6 class="text-uppercase" >
  <a href="/">Home</a>

  <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>

   <router-link :to="this.$route.params.category === 'agriculture-products' ? `/${this.$route.params.location}/agriculture-products` : this.$route.params.category === 'agriculture-equipments' ? `/${this.$route.params.location}/agriculture-equipments` : `/${this.$route.params.location}/livestocks`">
                  {{ this.$route.params.category === 'agriculture-products' ? translations.farmProducts : this.$route.params.category === 'agriculture-equipments' ? translations.farmEquipments : this.$route.params.category === 'live stocks' ? translations.Livestocks : this.$route.params.category }}
   </router-link>
  <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>
 <router-link  v-if="this.$route.params.categoryName" :to="{ name: 'FarmPagefilter', params: { categoryId: productList.category_id, categoryName: productList.category_name, category: productList.category_type === 'live stocks' ? 'livestocks' : productList.category_type, location: this.$route.params.location } }">
    {{ this.$route.params.categoryName.replace(/-/g, ' ') }}
  </router-link>
</h6>

      </div>
    </div>
  <div class="full-list">
    <div class="row">
     <div class="burger-menu" @click="toggleFilter">
    <input type="checkbox" id="toggle" class="input-toggler" v-model="isFilterOpen" />
    <label for="toggles" class="menu-toggler">
        <span class="menu-toggler-line"></span>
        <span class="menu-toggler-line"></span>
        <span class="menu-toggler-line"></span>
    </label>
    <span class="filter-text">{{ translations.locationFilter }}:</span>
     
</div>


      <div class="col col-sm-12 col-md-3 col-lg-2 filter me-0 pe-0" :class="{ 'responsive-filter': isFilterResponsive, 'open': isFilterOpen }" >
        <h2 class="my-3 px-3 d-flex">{{ translations.locationFilter }}: <div class="close-button" @click="closeForm">
                <i class="fa-solid fa-circle-xmark" style="color: #71a600;"></i>
              </div></h2> 
        <div class="count px-3">
          <div class="location three">
            <h3 class="sub-head 
            " >{{translations.selectdist}}:</h3>
            <div class="location-list pb-3 ">
              <div v-if="loading">
                <div v-for="index in 5" :key="index" >
                  <p class="shimmer-line"></p>
                  <p class="shimmer-line"></p>
                  <p class="shimmer-line"></p>
                </div>
              </div>
              <div class="">
                <div v-for="district in district" :key="district.market_id" >
                  <!-- <router-link :to="getFilterRoute(district.market_name)"> -->
                    <li @click="getFilterRoute(district.market_name)" :class="{ 'highlighted': this.$route.params.city ? false : isCurrentMarket(district.market_name) }">{{selectedLanguage === 'en' ? district.market_name : district.tn_name}} ({{ district.count }})</li>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div> 
          <div>
            <div class="location three">
              <h3 class="pt-4 town">
                {{ selectedLanguage === 'en' 
                  ? (isCity 
                    ? translations.selecttown + " " + "in" + " " + (matchingLocation?.place?.split(',')[1]?.trim() ?? '') 
                    : translations.selecttown + " " + "in" + " " + ($route.params?.location?.replace(/-/g, ' ') ?? '')
                  ) 
                  : (isCity 
                    ? (matchingLocation?.tn_place?.split(',')[1] ?? '') + " " + translations.selecttown 
                    : (matchingLocation?.tn_name ?? '') + " " + translations.selecttown
                  ) 
                }}:
              </h3>
              
            <div class="location-list  pb-3">
              <div>
                <div v-if="loading" >
                  <div v-for="index in 5" :key="index" >
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                  </div>
                </div>
                <div v-else>
                  <div v-for="city in cities" :key="city.market_id" >
                    <!-- <router-link :to="getFilterRoute(city.place)"> -->
                       <li @click="getFilterRoute(city.place,true)" :class="{ 'highlighted': isCurrentMarket(city.place, true) }">{{ selectedLanguage === 'en' ? city.place:city.tn_place }} ({{ city.count }})</li>
                    <!-- </router-link> -->
                  </div>
                </div>
              </div>
            
            </div>
            </div>
            <h3 class="pt-4 town"></h3>
          </div>
        </div>
        
      </div> 
      <div class="col col-md-12 col-sm-12 col-lg-10 mx-0 px-4 sroll">
        
        <div class="row list-page" v-if="productList.length > 0">
          <div v-if="$route.name === 'AllList'">
            <h1 class="my-3 pb-1 ms- border-bottom searched-for"><span> {{ this.totalLists}} {{translations.searchFor}}</span></h1>
          </div>
          <div class="d-none" id="notdisplay">
            <h6>Matching Location ID:</h6>
            <p v-if="matchingLocation">
              {{ matchingLocation.market_id ? 'marketid: ' + matchingLocation.market_id : 'marketplaceid: ' +
                matchingLocation.market_place_id }}
            </p>
            <p v-else>No matching location found.</p>
          </div>
          <div class="col-md-4 col-lg-3 col-6 col-xlg-10 p-1 m-0 my-1 " v-for="product in productList"
            :key="product.product_id || product.equip_product_id">
            <div class="product-grid p-0 m-0" v-if="!loading">
              <div class="product-image" >
              
                

                <router-link
  :to="getProductRoute(product, $route.params)"
  target="_blank"
>
  <a class="image">
    <img
      class="pic-1"
      :src="getProductImage(product)"
      :alt="`${product.category_name}-${product.product_id || product.equip_product_id}`"
      @error="handleImageError"
    />
  </a>

  <div class="del product-sale-labels" style="width:30px; height:30px;">
    <img
      class="delivery bg-success rounded-circle"
      @mouseover="showDeliveryMessage = true"
      @mouseleave="showDeliveryMessage = false"
      v-if="product.delivery"
      src="../assets/img/delivery2.png"
      alt="delivery"
    />
    <img v-else />
    <div
      v-if="showDeliveryMessage && product.delivery"
      class="delivery-above bg-success rounded text-light text-center text-nowrap m-0 px-2"
    >
      {{ translations.delivery }}
    </div>
  </div>

  <span class="product-sale-label">
    {{
      selectedLanguage === 'en'
        ? product.user_type
        : product.user_type_tamil
    }}
  </span>

  <div
    class="price my-1"
    v-if="product.price !== '00' && product.price !== '0' && product.price !== ''"
  >
    <i class="fa fa-inr rupee ps-1" aria-hidden="true"></i>
    {{ product.price }}/
    {{
      selectedLanguage === 'en'
        ? product.quantity_type
        : product.quantity_type_tamil
    }}
  </div>

  <div v-else class="text-warning"></div>
</router-link>
</div>
              <div class="product-content">
               <h2 class="title" >
                  <router-link :title="`${product.sub_category_name || product.category_name} - ${product.product_id || product.equip_product_id}`" to=""  @click="openInNewTab(product)" >
                    {{ product.name || product.business_name }}
                  </router-link>        
                </h2>
                <ul class="rating d-md-flex justify-content-between mt-1">
                  <li class="cities-dis"> <span class="text-nowrap">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">
    
                      <defs>
                      </defs>
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                      </svg> {{
                        selectedLanguage === "en"
                        ? product.place
                        : product.tn_place
                      }},
                  </span>    {{
    selectedLanguage === "en"
    ? product.market_name
    : product.tn_name
  }}  </li>
    <li >  {{
      new Date(product.created).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      })
    }}</li>
                </ul>
              </div>
            </div>

            <!-- Shimmer effect while loading -->
            <div class="product-grid shimmer mt-3" v-else>
              <div class="product-image"></div>
              <div class="product-content">
                <div class="shimmer-line" style="height:150px"></div>
                <div class="shimmer-line"></div>
                <div class="shimmer-line"></div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <!-- Shimmer effect while loading -->
          <div v-if="loading">
            <div class="row mt-5">
              <div v-for="index in 12" :key="index" class="col-md-4 col-lg-3 col-sm-2">
                <p class="shimmer-line " style="height:150px"></p>
                <p class="shimmer-line"></p>
                <p class="shimmer-line"></p>
              </div>
            </div>


          </div>
          <div v-else class="justify-content-center text-center">
             <div class="nodata-img">
              <img class="" src="../assets/img/why/emptypro.webp" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                {{ translations.no_data_product }}  
               </h2>
               
               <p class="no-data-para">{{ translations.no_data_product_para }}</p>
            <!-- <h2 class="my-3 no-data">{{`${$route.params.product?.replaceAll('-',' ')} will be coming soon in ${this.$route.params?.city?.replaceAll('-',' ') || $route.params.location?.replaceAll('-',' ')}!`}} <br> </h2>-->
              <button class="mt-5 btns-soon my-2 px-4 py-2"><a href="/">Go Home</a> </button> 
            
          </div>
        </div>
<div class="text-center mt-3 mb-3 view">
            <button
              v-if="currentPage < totalPages && !loading"
              @click="loadMoreData"
              class="btns my-2 px-4 py-2"
            >{{translations.viewmore}}</button>
            <p
              v-if="currentPage < totalPages"
            >{{translations.page}} {{currentPage}} / {{totalPages}}</p>
          </div>
        <div class="col-lg-12">
        
          <div v-html="more" :id="$style.more"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { en, ta } from "@/translations";
import {disableScroll} from "@/router"
import axios from "axios";
export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      productList: [],
      loading: false,
      currentPage: 1,
      totalPages: 1,
      hasMorePages: true,
      district: [],
      cities: [],
      totalLists: 0,
      matchingLocation: null, // New property to store the matching location
      showDeliveryMessage: false,
      isFilterResponsive: false,
      isFilterOpen: false,
      isCity: false,
      details:{},
      selectedCity: '',
      showForm: false,
    };

  },
  created() {
    this.isCity = false
    this.$nextTick(() => {
      this.fetchProductList();
    });

  },

  watch: {
    // Watch for changes in the route parameters
    '$route.params.city': {
      handler: 'handleRouteChange',
      deep: true,
      // immediate: true,
    },
    '$route.params.location': {
      handler: 'handleRouteChange',
      deep: true,
      // immediate: true,
    },
     isFilterOpen(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },  
    $route() {
      // Call the fetchProductList method when the route changes
    }
  },
  methods: {
     closeForm() {
      this.isFilterOpen = false; 
    },
openInNewTab(product) {
    const routeData = this.getProductRoute(product, this.$route.params);
    const resolvedRoute = this.$router.resolve(routeData);
    window.open(resolvedRoute.href, '_blank');
  },
  handleResize() {
      this.isFilterResponsive = window.innerWidth <= 768;
    },
    toggleFilter() {
      if (this.isFilterResponsive) {
        this.isFilterOpen = !this.isFilterOpen;
      }
    },
 getProductRoute (product, route = null){
    let params = { ...route };
    // params.seller = product.seller.toLowerCase() || "seller"
    
    if (product.product_id) {
      console.log("product_id", product.product_id);
      params.id = product.product_id;
      return {
        name: product.category_type === 'live stocks' ? 'livestockdetails' : 'resultproduct',
        params
      };
    } else if (product.equip_product_id) {
      console.log("equip_product_id", product.product_id);
      params.id = product.equip_product_id;
      return { name: 'equipment', params };
    }
  
    return { name: 'fallbackRoute' };
  },
  async translateToEnglish(text) {
  try {
    const response = await fetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=en&dt=t&q=${encodeURIComponent(text)}`
    );
    const data = await response.json();
    return data[0][0][0]; // Extract translated text
  } catch (error) {
    console.error("Error translating text:", error);
    return text; // Fallback to original if translation fails
  }
},
    isCurrentMarket(marketName, isCity = false) {

      console.log('name->',marketName, isCity)
      
      let isMatched = isCity ? this.$route.params?.city?.replace(/-/g,' ').toLowerCase() === marketName.toLowerCase()
      : this.$route.params?.location?.replace(/-/g,' ').toLowerCase() === marketName.toLowerCase();
      console.log('matched->',isMatched)

      if (isMatched) {
        if(isCity) {
          this.isCity = true
        }
      }
      return isMatched;
    },

    handleCityClick(place) {
      this.getProductRoute(place);
      this.isFilterOpen = false; // Close the filter sidebar
    },

    getTag(item, index) {
    let style = {}; 
    if (index === 0) {
      style.color = 'black';
      style.fontSize = '28px'; 
      style.fontWeight = '600';
      return { tag: 'h1', style }; 
    } else if (item.key && (item.key.toLowerCase().includes('buy') || item.key.toLowerCase().includes('sell'))) {
      style.fontSize = '23px'; 
      style.fontWeight = '600';
      return { tag: 'h2', style }; 
    } else {
      style.fontWeight = '600';
      style.fontSize = '20px'; 
      return { tag: 'h3', style }; 
    }
  },
    getProductImage(product) {
    if (product.images && product.images.length > 0 && typeof product.images[0] === 'string' && product.images[0].trim() !== '') {
      return product.images[0];
    } else {
      return require('@/assets/img/drive/Empty Image.webp');
    }
  },
  handleImageError(event) {
    event.target.src = require('@/assets/img/drive/Empty Image.webp');
  },
    truncatetitle(name) {
      if (name.length > 20) {
        return name.substring(0, 17) + "...";
      }
      return name;
    },
    truncatebtitle(business_name) {
      if (business_name.length > 25) {
        return business_name.substring(0, 25) + "...";
      }
      return business_name;
    },

    getFilterRoute(location, city = false) {
      this.selectedCity = location
      // console.log('sel-city:',this.selectedCity)
      let params = {...this.$route.params}
      city ?
        (
          // params.location = location.replace(' ','-').toLowerCase() || 'tamilnadu',
          params.city = this.selectedCity.replace(' ','-').toLowerCase() 
        )
        :( 
          params.location = location.replace(' ','-').toLowerCase() || 'tamilnadu',
          params.city = null
        )

     
      this.$router.push({
        name: 'AllList',
        params: params,
      })
     
    },
    async handleRouteChange() {
     
      this.currentPage = 1;
      this.totalPages = 1;
      this.hasMorePages = true;
      this.isCity = false;
      this.productList = [];
      await this.fetchProductList();
    },
    async findMatchingLocation() {
      const locationParam = this.$route.params?.location?.replace(/-/g,' ');
      const beforeComma = (locationParam ?? '').split(",")[0].trim();

      const response = await axios.get(`https://vaiyal-app.herokuapp.com/get/location?search=${this.$route.params?.location?.replace(/-/g,' ')}`);

      if (response.data.status === 'success') {
        const locationData = response.data.data;

        const matchingLocation = locationData.find(({ market_name,tn_name }) => {
            return (market_name?.replace(/-/g,' ').toLowerCase() === beforeComma.replace(/-/g,' ').toLowerCase(),tn_name);
        });
        if (matchingLocation) {
          this.matchingLocation = matchingLocation;
          // console.log('Matching location found:', matchingLocation);
        } else {
          this.matchingLocation = null;
          // console.log('No matching location found.');
        }
      } else {
        console.error('Error fetching location data:', response.data.msg);
      }
      if(this.$route.params.city) {
          const response = await axios.get(`https://vaiyal-app.herokuapp.com/get/location?search=${this.$route.params?.city?.replace(/-/g,' ')}`);
          // console.log(response,'dddddddd');

          if (response.data.status === 'success') {
            const locationData = response.data.data;
            const matchingCity = locationData.find(({ place,tn_place }) => {
                const placeParts = place?.split(',').map(part => part.trim());
                return (placeParts && placeParts[0].replace(/-/g,' ').toLowerCase() === beforeComma.replace(/-/g,' ').toLowerCase(),tn_place);
            });
            if (matchingCity) this.matchingLocation = { ...this.matchingLocation, ...matchingCity} 
            else console.error('Error fetching city data with list:', response.data.msg);
          }
          else {
            console.error('Error fetching city data:', response.data.msg);
          }
        }
    },


   async fetchProductList() {
  try {
    // Show loading indicator
    this.loading = true;

    // Ensure that findMatchingLocation is called and awaited before proceeding
    await this.findMatchingLocation();

    // Extract product and market details
    const product = this.$route?.params?.product?.replace(/-/g, ' ')?.replaceAll('&', '%26');
    const marketId = this.matchingLocation ? this.matchingLocation.market_id : null;
    const marketPlaceId = this.matchingLocation ? this.matchingLocation.market_place_id : null;

    console.log('marketPlaceId', marketPlaceId);

    // Make API call to fetch product list
    const response = await axios.post(
      `https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`,
      { marketid: marketId, marketplaceid: marketPlaceId }
    );

    if (response.data.status === "success") {
      // Handle pagination and other response data
      this.totalPages = response.data.pages;
      this.hasMorePages = this.currentPage < this.totalPages;
      this.district = response.data.district;
      this.cities = response.data.cities;
      this.totalLists = response.data.totalLists;
      this.more = response.data.more;
      this.details = {
        title: response.data.title,
        description: response.data.description,
        tn_title: response.data.tn_title,
        tn_description: response.data.tn_description,
      };

      // Extract and format the API category name and type
      const apiCategoryName = response.data.data[0]?.category_name;
      const currentCategoryName = this.$route.params.categoryName;

      const apiCategoryType = response.data.data[0]?.category_type;
      console.log("apiCategoryType-all",apiCategoryType)
      const currentCategoryType =
        this.$route.params.category === 'agriculture-products' ? 'Agriculture Products' :
        this.$route.params.category === 'agriculture-equipments' ? 'Agriculture Equipments' :
        this.$route.params.category === 'live stocks' ? 'Livestocks' :
        this.$route.params.category;

      let needsRouteUpdate = false; 
      let newRouteParams = { ...this.$route.params };

      if (apiCategoryName && apiCategoryName !== currentCategoryName) {
        newRouteParams.categoryName = apiCategoryName.replace(/\s+/g, '-').toLowerCase();
        needsRouteUpdate = true;
      }

      if (apiCategoryType && apiCategoryType !== currentCategoryType) {
        newRouteParams.category = apiCategoryType === "live stocks" ? "livestocks" : apiCategoryType.replace("farm", "agriculture").replace(/\s+/g, '-').toLowerCase();
        console.log("newRouteParams.category-alllist",newRouteParams.category)
        needsRouteUpdate = true;
      }

      // If any update is needed, replace the current route params
      if (needsRouteUpdate) {
    const newRoute = { ...this.$route, params: newRouteParams };
    if (JSON.stringify(newRoute.params) !== JSON.stringify(this.$route.params)) {
        disableScroll(); 
        this.$router.replace(newRoute);
    }
}

      // Handle product list data
      if (this.currentPage === 1) {
        this.productList = response.data.data;

       
      } else {
        this.productList = [...this.productList, ...response.data.data];
      }
    } else {
      console.error("Error fetching product list:", response.data.msg);
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
  } finally {
    this.loading = false;
  }
}

,
 async loadMoreData() {
      this.currentPage++;
      await this.fetchProductList();
    },

  
  },
  mounted() {
     window.addEventListener("scroll", this.handleScroll);
     window.addEventListener('resize', this.handleResize);
     this.handleResize();
  },



  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
     window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    locationParts() {
      const locationParam = this.$route.params?.location?.replace(/-/g,' ');
      const [beforeComma, afterComma] = locationParam
        .split(",")
        .map(part => part.trim());
      return {
        beforeComma,
        afterComma
      };
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed&family=Montserrat&family=Mulish&family=Work+Sans&display=swap');
.del .delivery{
  width: 30px;
  height:30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
.full-list {
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
}

.list-page {
  margin: 20px 0px;
}

.list-page h2 {
  font-size: 16px;
}

.list-page h2 span {
  font-size: 18px;
  color: #618626;
}

.searched-for {
  font-size: 16px;
}

.searched-for span {
  font-size: 18px;
  color: #618626;
}

.no-data {
  font-size: 20px;
  font-weight: 600;
}

.no-data-para{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img{
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img img{
  width: 100%;
}
.demo {
  background-color: #e7e7e7;
}

.left-title h3 {
  font-size: 18px;
  font-weight: 600;
  color: #16123f;
  padding-left: 30px;
}

.product-grid {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin: 5px 0px 0;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: all 0.5s;
  height: 300px;
}

.product-grid:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.product-grid .product-image {
  position: relative;
  cursor: pointer;
}

.product-grid .product-image a.image {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  display: block;
}

.product-grid .product-image a.image img {
  height: 200px;
}

.product-grid .product-image img {
  width: 100%;
  height: auto;
}

.product-grid .product-sale-label {
  color: #5A5A5A;
  background: #FFDF66;
  font-size: 11px;
  padding: 2%;
  text-transform: uppercase;
  border-radius: 3px;
  position: absolute;
  left: 0px;
  top: 2px;
}

.product-grid .price {
  color: #fff;
  background: #1C6402;
  font-size: 13px;
  padding: 2px 6px 2px 18px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 1px 3px 3px 1px;
  position: absolute;
  top: 30px;
  left: 0px;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
}

.product-grid .price span {
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.5;
}

.product-grid .price:before {
  content: "";
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 7px;
}

.product-grid .price.fixed {
  background: #b71540;
  top: -4px;
}

.product-grid .add-to-cart {
  color: #fff;
  background: #b71540;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 10px;
  border-radius: 3px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: all 0.2s ease-out;
}

.product-grid .add-to-cart:hover {
  text-shadow: 0 0 5px #000;
}

.product-grid:hover .add-to-cart {
  opacity: 1;
}

.product-grid .product-content {
  background-color: #fff;
  text-align: left;
  padding: 15px 10px;
  border-top: 1px solid transparent;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s;
    height: 100px;
}

.product-content h2 {
  font-size: 16px;

  font-weight: 600;
  color: #16123f;
}

.product-grid:hover .product-content {
  border-top-color: #dbdbdb;
}

.product-grid .title {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.product-grid .title a {
  color: #1B4332;
  transition: all 0.4s ease-out;
}

.product-grid .title a:hover {
  color: #b71540;
}

.product-grid .rating {
  padding: 0;
  margin: 0;
  list-style: none;
}

.product-grid .rating li {
  color: #1F5F5B;
  font-size: 12px;
  display: inline-block;
}

.product-grid .rating li.far {
  color: #dbdbdb;
}

.shimmer {
  animation: shimmer 1s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
}

.shimmer-line {
  height: 20px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

@media screen and (max-width: 990px) {
  .product-grid {
    margin: 5px 15px 30px;
  }

}

.filter h2 {
  font-size: 18px;
  
}

.filter h2 span {
  font-size: 14px;
}

.count h3 {
  font-size: 16px;
  color: #16123f;
}

.location-list {
  height: 100%;
  max-height: 200px;
  overflow-y: scroll;
}

.location-list a {
  text-decoration: none;
  color: black;
}

.location-list li {
  padding: 3px 10px;
  font-size: 14px;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}

.location-list li:hover {
  background: #71a600;
  color: aliceblue;
}

.highlighted {  
  background: #618626;
  color: aliceblue;
}

::-webkit-scrollbar {
  width: 5px;
    height: 5px;

}

::-webkit-scrollbar-thumb {
  background: #618626;
  border-radius: 10px;
}

.location-list ::-webkit-scrollbar-track {
  background-color: #fcf6f5;
}


img{
  object-fit: cover;
}

@media (max-width: 993px) {
 
}

/* === HEADING STYLE #3 === */
.three h3 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1em;
  padding-bottom: 15px;
  position: relative;
}
.three h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #71A600;
}
.three h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #71A600;
}
@media (min-width: 1800px) {
.full-list{
  margin: 0 15%;
}
}

@media (min-width: 320px) and (max-width: 660px) {
  .rating{
   display:flex;
   justify-content: space-between;
  }

.product-grid .title {
   height: 45px;
   width:100%;
  overflow: hidden;
  text-overflow: ellipsis; 
}
}

.burger-menu{
  display: none;
}
@media (min-width: 320px) and (max-width: 768px) {
  .burger-menu {
    display: block; 
  }
  .burger-menu {
    display: flex;
    align-items: center; 
}

.filter-text {
    margin-left: 0px;
    font-size: 20px;
  color:#1c6402 ;
  margin-top: -12px;
  font-weight: bold;
}

.filter{
  height: 100%;
  overflow-y: scroll;
}
.filter .close-button{
  visibility: visible;
  font-size: 25px;

}
  .responsive-filter {
    position: fixed;
    top: 100px;
    left: -100%;
    transition: left 0.3s ease;
    z-index: 999;
  }

  .responsive-filter.open {
    left: 0;
  }

  
.input-toggler {
  display: none;
}

.menu-toggler {

  top: 0px;
  width: 50px;
  height: 70px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggler-line {
  width: 50%;
  height: 3px;
  background: #1c6402;
  margin: 0 0 6px 0px;
  position: relative;
  transition: all 0.4s ease-out;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line {
  top: 6px;
  translatey : 90px;
  transform: rotate(45deg);
}


.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(2) {
  display: none;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(3) {
  top: -3px;
  translatey : 30;
  transform: rotate(135deg);
}
  .pagelink{
      background-image: url("../assets/img/drive/Detail-Banner-mbl.webp") !important;
  }
}
.list-head{
  font-size: 18px;
  font-weight: 600; 
  text-align: justify;
}

.list-para{
  padding-bottom: 1%;
  font-size: 14px;
  text-align: justify;
}

.value-para{
  font-size: 14px;
  text-align: justify;
  padding-top: 1%;
}
.btns{
  background: #71A600;
  color: white;
  font-family: 'satisfy';
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: none;
  border-radius: 6px;
}
.btns-soon{
  background: #71A600;
  color: white;
  font-family: 'satisfy';
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: none;
  border-radius: 6px;
}

.btns-soon a{
  text-decoration: none;
  color: white;
}
.view button {
  font-size: 16px;
}
.view p{
  color: #355e3b;
}

.cities-dis{
  white-space: nowrap; 
  width:200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.one h1 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  font-weight: 800;
  position: relative;
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 13px;
  left: 50%;
  margin-left: -14px;
  background-color: #71A600;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  left: 50%;
  margin-left: -50px;
  background-color: #71A600;
}

 .pro-des{
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family:"Mukta"
}

.pagelink {
  background-image: url("../assets/img/drive/Detail-Banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.pagelink h1 {
  color: #238601;
  font-family: "Mukta";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pagelink h6 {
  color: #238601;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.pagelink h6 a {
  text-decoration: none;
  color: #238601;
}

.pagelink h6 a:hover {
  text-decoration: none;
  color: #134801;
}

/* .filter {
  position: sticky;
  top: 0; 
  height: 100vh; 
  overflow-y: auto; 
}


.sroll {
  height: 100%;
  overflow-y: scroll; 
  max-height: 100vh; 
  padding-right: 15px;
} */
.filter h2{
  justify-content: space-between;
}

.close-button {
  display: none;
}

/* Show the button only for screens 768px or smaller */
@media (max-width: 768px) {
  .close-button {
    display: block;
  }
}

</style>

<style module>
#more > h1{
  font-size: 28px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}
#more > h2{
  font-size: 22px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}
#more > h3{
  font-size: 18px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}

#more > span{
  font-family: 'Work Sans', sans-serif !important;

}
#more > ul > p >span{
    font-family: 'Work Sans', sans-serif !important;

}



#more > ul > li > h4{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}
#more > ol > li > h4{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}

#more > ul > li > h4 a{
 color: black;
}
#more > ol > li > h4 a{
 color: black;
}

#more > p{
  font-size: 13px;
  font-family: 'Work Sans', sans-serif !important;
  text-align: justify !important;

}
#more > ol > li {
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  padding-bottom: 2%;
}
#more > ol > li > span{
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}
#more > ul >li{
  font-size: 14px;
  padding-bottom: 2%;
  font-family: 'Work Sans', sans-serif !important;
}
#more > ul > li > span{
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}
#more > ul >li:nth-child(5){
  padding-bottom: 0%;
}



</style>
