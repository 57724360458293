import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import 'bootstrap/dist/css/bootstrap.css';
import CanvasJSStockChart from '@canvasjs/vue-stockcharts';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery';
import 'popper.js';
import { createPinia } from 'pinia';
const app = createApp(App);

// Set up global beforeRouteEnter guard to handle meta tags
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'VayalAgro'; // Set a default title if necessary
  }

  // You can add more meta tags as needed here
  if (to.meta.metaTags) {
    const metaTags = to.meta.metaTags;
    for (const tag of metaTags) {
      const tagElement = document.createElement('meta');
      tagElement.setAttribute(tag.name, tag.content);
      document.head.appendChild(tagElement);
    }
  }

  next();
});
app.use(CanvasJSStockChart); // install the CanvasJS Vuejs StockChart Plugin

app.use(router).use(createPinia()).mount('#app');
