<template>
    <div class="container">

   <div class="banner ">
    <h1 class="heading"> {{ $route.params.categoryName.replace(/_/g, ' ') }}</h1>
    <div class="loco searchbox d-md-flex justify-content-center">
    
      <div class="dist mx-3" v-if="markets.length > 0">
        <h6>{{ translations.selectdist }}</h6>
        <select class="selec " v-model="selectedMarket" searchable>
          <option v-for="market in markets" :key="market.market_id" :value="market.market_id" :selected="market === markets[0]">
            {{ selectedLanguage === "en" ? market.market_name : market.tn_name }}
          </option>
        </select> 
      </div>
      <!-- <div v-else>
        <p>No markets available</p>
      </div> -->

      <div class="town mx-3">
        <h6>{{ translations.selecttown }}</h6>
        <select class="selec" v-model="selectedPlace">
          <option value="0">{{ translations.all }}</option>
          <option v-for="place in placeData" :key="place.market_place_id" :value="place.market_place_id">
            {{ selectedLanguage === "en" ? place.place : place.tn_place }}
          </option>
        </select>
      </div>
    </div>

    <div class="table-container text-center mt-3">
        <div v-if="isLoadingData">
          <!-- Shimmer effect for loading -->
          <div v-for="index in 6" :key="index">
            <p class="shimmer-line" style="height: 40px"></p>
            <p class="shimmer-line" style="height: 40px"></p>
            <p class="shimmer-line" style="height: 40px"></p>
          </div>
        </div>
        <div v-else-if="marketPriceData.Dates.length">
           <div class="d-sm-none">
          <table class="table table-responsive w-100 text-center px-md-5 px-sm-2 px-2 ">
            <thead>
            
              <tr class="text-nowrap head">
                <!-- <th>{{translations.category}}</th> -->
                <th :class="{ 't-tamil': selectedLanguage === 'ta', 't-heads': selectedLanguage !== 'ta' }">{{translations.date}}</th>

                <th :class="{ 't-tamil': selectedLanguage === 'ta', 't-heads': selectedLanguage !== 'ta' }">{{translations.view}}</th>

                <!-- Add more headers as needed -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="dateItem in marketPriceData.Dates" :key="dateItem.created" class="text-nowrap">
                <!-- Display relevant information from the response -->
  
                <!-- <td>
                    <span class="material-symbols-outlined"> location_on </span>
                    {{
                      selectedLanguage === "en" ? dateItem.place : dateItem.tn_place
                    }}
                <br>
                </td> -->
                <td class="text-nowrap" 
                  :class="{ 'vist-tamil': selectedLanguage === 'ta', 'vist': selectedLanguage !== 'ta' }"
                >
                  
                    {{ dateItem.created }}

                </td>
         
                <!-- Add more cells as needed -->
                <td class="text-nowrap click"  
                  :class="{ 'vist-tamil': selectedLanguage === 'ta', 'vist': selectedLanguage !== 'ta' }"
                   @click="redirectToPriceSublist(dateItem)">
                  <!-- Add a router-link to navigate to AllRecent with parameters -->
           {{ translations.viewprice }} &nbsp; &nbsp;
  
                </td>
              </tr>
            </tbody>
          </table>
           </div>
         <div class="table-1-web d-none d-sm-block">
           <table class="table table-responsive w-100 text-center px-md-5 px-sm-2 px-2 ">
            <thead>
            
              <tr class="text-nowrap head">
                <!-- <th>{{translations.category}}</th> -->
                <th :class="{ 't-tamil': selectedLanguage === 'ta', 't-heads': selectedLanguage !== 'ta' }">{{translations.date}}</th>
                <th :class="{ 't-tamil': selectedLanguage === 'ta', 't-heads': selectedLanguage !== 'ta' }">{{translations.place}}</th>

                <th :class="{ 't-tamil': selectedLanguage === 'ta', 't-heads': selectedLanguage !== 'ta' }">{{translations.view}}</th>

                <!-- Add more headers as needed -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="dateItem in marketPriceData.Dates" :key="dateItem.created" class="text-nowrap">
                <!-- Display relevant information from the response -->
  
                
                <td class="text-nowrap " :class="{ 'vist-tamil': selectedLanguage === 'ta', 'vist': selectedLanguage !== 'ta' }">
                  
                    {{ dateItem.created }}

                </td>
                <td class="text-nowrap " :class="{ 'vist-tamil': selectedLanguage === 'ta', 'vist': selectedLanguage !== 'ta' }">
                  
                   {{ selectedLanguage === "en" ? dateItem.place : dateItem.tn_place }}

                </td>
         
                <td class="text-nowrap click " 
                :class="{ 'vist-tamil': selectedLanguage === 'ta', 'vist': selectedLanguage !== 'ta' }"
                   @click="redirectToPriceSublist(dateItem)">
           {{ translations.viewprice }} &nbsp;
  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        <div v-else>
          <p class="px-4 my-0 nodata mx-5">
            <img src="../../assets/img/drive/Empty Image.webp" loading="lazy" alt="Market Price">
          </p>
        </div>
      </div>
    <div v-if="marketPriceData.Dates.length > 0" class="pagination">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button class="page-link" @click="prevPage" :disabled="currentPage === 1">
              <span aria-hidden="true" class="text-custom">&laquo;</span>
            </button>
          </li>
          <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }">
            <button class="page-link" @click="goToPage(page)" :class="{
              'bg-custom-active': page === currentPage,
              'text-custom': page !== currentPage,
            }">
              {{ page }}
            </button>
          </li>
          <li class="page-item">
            <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages">
              <span aria-hidden="true" class="text-custom">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <div class="disclaimer">
      <p class="text-center my-3 container" v-if="disclaimer"><strong>Disclaimer:</strong> {{ disclaimer }}</p>
    </div>
  </div>
   
</div>
</template>

<script>
import { en, ta } from "@/translations";

export default {
props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

data() {
  return {
    selectedMarket: this.$route.query.selectedMarket,
    markets: [],
    selectedPlace: this.$route.query.selectedPlace || "0",
    placeData: [],
    marketPriceData: { Dates: [] },
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    isLoadingData: true,
    isInitialLoad: true, // Flag to determine initial load

  };
},
created() {
  const storedPageNumber = localStorage.getItem("currentPageNumber");
  if (storedPageNumber) {
    this.currentPage = parseInt(storedPageNumber);
    localStorage.removeItem("currentPageNumber"); // Remove the stored page number
  }
  this.fetchMarketData();
},

computed: {
  visiblePages() {
    const numPagesToShow = 5; // Adjust this value to determine how many pages to show
    const halfNumPages = Math.floor(numPagesToShow / 2);
    const firstVisiblePage = Math.max(1, this.currentPage - halfNumPages);
    const lastVisiblePage = Math.min(
      this.totalPages,
      this.currentPage + halfNumPages
    );
    return Array.from(
      { length: lastVisiblePage - firstVisiblePage + 1 },
      (_, index) => firstVisiblePage + index
    );
  },

  translations() {
    return this.selectedLanguage === "en" ? en : ta;
  },
},
watch: {
  selectedMarket: {
  handler() {
    this.currentPage = 1; // Reset the current page to 1 when the market changes
    
    this.fetchPlaceData();
    this.fetchMarketPriceData();

    // Set selectedPlace to default value when selectedMarket changes, not on initial load
    if (!this.isInitialLoad) {
      this.selectedPlace = "0"; // Set the default value
    } else {
      this.isInitialLoad = false; // Set the flag to false after the initial load
    }
  },
  immediate: true,
},
  selectedPlace: {
    handler() {
      this.currentPage = 1; // Reset the current page to 1 when the place changes
  
      this.fetchMarketPriceData();
    },
    immediate: true,
  },
},

methods: {
    redirectToPriceSublist(dateItem) {
  const selectedMarket = this.markets.find(
    (market) => market.market_id === this.selectedMarket
  );

  const queryParams = {
    // categoryId: this.$route.params.categoryId,
    categoryName: this.$route.params.categoryName.replace(/\s+/g, '-')?.toLowerCase(),
    // marketId: this.selectedMarket,
    // marketplaceId: dateItem.market_place_id,
    created: dateItem.created,
    city: dateItem.place.replace(/\s+/g, '-')?.toLowerCase(),
    location: selectedMarket ? selectedMarket.market_name : "",
  };

  this.$router.push({
    name: "pricesublist",
    params: queryParams,  // Use params instead of query
  });
},


  async fetchMarketData() {
  try {
    const category_id = this.$route.params.categoryId;
    const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=market&categoryid=${category_id}`;

    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data.status === "success") {
      this.selectedMarket = data.data.length > 0 ? data.data[0].market_id : null;

      this.markets = data.data;
    } else {
      console.error("API response error:", data.msg);
    }
  } catch (error) {
    console.error("Error fetching market data:", error);
  }
},
  async fetchPlaceData() {
    if (this.selectedMarket) {
      try {
        const category_id = this.$route.params.categoryId;
        const category = this.selectedMarket;
        // console.log(category);
        const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=market&categoryid=${category_id}&marketid=${category}`;
        const response = await fetch(apiUrl);

        const data = await response.json();
        if (data.status === "success") {
          this.placeData = data.data;
        } else {
          console.error("API response error:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching place data:", error);
      }
    } else {
      this.placeData = []; // Reset placeData if no market is selected
      this.selectedPlace = ""; // Reset selectedPlace
    }
  },

  async fetchMarketPriceData() {
    this.isLoadingData = true; // Set loading state to true

    if (
      this.selectedMarket &&
      this.selectedPlace &&
      this.$route.params.categoryId
    ) {
      try {
        const response = await fetch(
          `https://vaiyal-app.herokuapp.com/getmarketprice?page=${this.currentPage}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              marketid: this.selectedMarket,
              categoryid: this.$route.params.categoryId,
              marketplaceid: this.selectedPlace,
            }),
          }
        );

        const data = await response.json();
        if (data.status === "success") {
          this.marketPriceData = data.data; // Update with fetched data
          this.totalPages = data.pages; // Update total pages based on the response
          this.itemsPerPage = data.itemsPerPage; // Update items per page based on the response
        } else {
          console.error("API response error:", data.msg);
          this.marketPriceData.Dates = []; // Reset marketPriceData if API response indicates no data
        }
      } catch (error) {
        console.error("Error fetching market price data:", error);
        this.marketPriceData.Dates = []; // Reset marketPriceData if an error occurs
      }
    } else {
      this.marketPriceData.Dates = []; // Reset marketPriceData if any parameter is missing
    }
    this.isLoadingData = false; // Set loading state to false
  },
  prevPage() {
    this.goToPage(this.currentPage - 1);
  },

  nextPage() {
    this.goToPage(this.currentPage + 1);
  },

  goToPage(pageNumber) {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      // Store the current page number in localStorage
      localStorage.setItem("currentPageNumber", pageNumber);

      this.currentPage = pageNumber;
      this.fetchMarketPriceData();

      // Scroll to the listdate section
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
      scrollToTop();
    }
  },
},
};
</script>
  
  
  <style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap');
  .full {
    font-family: "Raleway", sans-serif;
    margin: 0px 20px;
  }
  
  .banner {
    margin: 30px 0px;
  }
  
  .heading {
    margin: 10px 0px 0px 0px;
    text-align: center;
    color: #606060;
  }
  
  .listdate {
    padding: 30px 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 6px;
  }
  
  .listdate h5 {
    margin-bottom: 30px;
  }
  
  .catename {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(0, 128, 0, 0.9);
    margin: 20px 0px;
    font-family: "Merriweather", serif;
  }
  
  .loco {
    padding: 30px 20px;
    height: 100%;
    border-radius: 6px;
  }
  
  .searchbox h6 {
    font-size: 14px;
    color: rgba(70, 67, 67, 0.8);
  }
  
  .searchbox select {
    width: 100%;
    min-width: 200px;
    height: 40px;
    padding: 0px 10px;
    color: rgba(70, 67, 67, 0.8);
    border: 0.5px solid rgba(163, 159, 159, 0.8);
  }
  
  .dist,
  .town {
    margin: 20px 0px;
  }
  

  .dist select option:hover,
  .town select option:hover {
    background-color: rgba(0, 128, 0, 0.9);
    color: #ffffff;
  }
  
  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin: 10px 0px;
    padding: 7px 15px 0px 15px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-transform: capitalize;
  }
  
  .date h6 {
    font-size: 16px;
  }
  
  .created {
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
  }
  
  .nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    height: 300px;
  }
  
  .nodata img {
    margin-top: 50px;
    width: 400px;
  }
  
  .colourmaker .date {
    position: relative;
  }
  
  .colourmaker .date::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.9);
  
    z-index: -1;
  }
  
  .colourmaker .date:nth-child(odd)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.9);
    z-index: -1;
  }
  
  .colourmaker .date:nth-child(even)::before {
    background-color: rgba(145, 155, 5, 0.9);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    z-index: -1;
  }
  
  .material-symbols-outlined {
    font-size: 14px;
    color: rgba(0, 128, 0, 0.9);
    padding-right: 5px;
  }
  
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li {
    list-style: none;
    margin: 0 5px;
  }
  
  .pagination button{
    display: block;
    font-size: 12px;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.5s ease;
  }
  
  .pagination button:hover {
    background: #71A600;
}
  
  .pagination li.active button {
    background: #71A600;
    color: #fff;
    border-radius: 4px;
    border:  #71A600;
   
  }
  
  .pagination li.disabled button {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .bg-custom-active {
    background-color: green;
    color: white;
    border-radius: 4px;
  }
  
  .text-custom {
    color: green;
  }
  
  select.selec {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../assets/img/Icons/arrow_drop.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 8px 30px 8px 8px;
    border: 1px solid #ccc;
  }
  
  select.selec:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: green;
  }
  
  .loading-spinner i {
    font-size: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 768px) {
    .listdate {
      padding: 0px 20px;
    }
  
    .full {
      margin: 0px;
    }
  .table-1-web{
  width: 80% !important;
  margin-left: auto;
  margin-right: auto;
}
    .table .t-heads{
  background: #71A600;
  color: #fff;
  font-size: 14px;
      padding:10px !important;
width: 60% !important;
}
.table .vist{
  background: #f9ffed;
    padding:1%  !important;
 font-size: 14px;
}
  }
  
  @media (min-width: 322px) and (max-width: 575px) {
  
    .banner {
      display: block;
    }
  
    .full {
      margin: 0px;
    }

    
  .table {
      padding: 0 !important;
      overflow: hidden;
    }
.table .t-heads{
  background: #71A600;
  color: #fff;
  font-size: 14px;
      padding:10px !important;
      width: 100% !important;

}
.table .vist{
  background: #f9ffed;
    padding:2%  !important;
text-align: right;
}


  }
  
  @media (max-width: 375px) {
    .full {
      margin: 0px;
    }
  
    .boxdate h6 {
      font-size: 12px;
    }
  
    .material-symbols-outlined {
      font-size: 12px;
    }
  
    .searchbox h6 {
      font-size: 10px;
      color: rgba(70, 67, 67, 0.8);
    }
  
    .catename {
      font-size: 16px;
    }

    .table {
      padding: 0 !important;
      overflow: hidden;
    }
 

.table .t-heads{
  background: #71A600;
  color: #fff;
  font-size: 14px;
  padding:10px !important;
  width: 100% !important;
}
.table .vist{
  background: #f9ffed;
    padding:2%  !important;
    text-align: left;
}


.click {
    font-size: 14px !important;
  }

  }
  



  .disclaimer p{
    font-size: 14px;
  }
  .content p{
    font-size: 16px;
    font-weight: 600;
  }
  
  
  .table .t-heads {
    background-color: #f2f2f2;
    color: #16123f;
  }
  
.click {
    text-decoration: none;
    color: green;
  }
  
.click:hover {
    color: darkgreen;
  }
  
  td small {
    color: gray;
  }
  
  .table-container {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table .t-heads,
  .table .vist {
    border-top: 1px solid #71A600;
    border-bottom: 1px solid #71A600;
    padding: 10px 40px;
    text-align: left;
    width: 100%;
  }

  .table .t-heads{
    background: #71A600;
    color: #fff;
  }
  .table .vist{
    background: #f9ffed;
  }
  .table .t-tamil,
  .table .vist-tamil {
    border-top: 1px solid #71A600;
    border-bottom: 1px solid #71A600;
    padding: 10px 10px;
    text-align: left;
    width: 100%;
  }
  .table .t-tamil{
    background: #71A600;
    color: #fff;
  }
  .table .vist-tamil{
    background: #f9ffed;
    font-size: 16px;
  }
  
  .shimmer-effect {
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    margin-bottom: 10px;
  }
  
  @keyframes shimmer {
    to {
      background-position: -200% 0;
    }
  }
  
  h1 span {
    color: green;
  }
  
  .shimmer-line {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    height: 15px;
    margin-bottom: 10px;
  }
  
  .shimmer-line {
    height: 20px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    animation: shimmer 1s infinite linear;
  }
  
  @keyframes shimmer {
    to {
      background-position: -200% 0;
    }
  }
  
  .click{
    cursor: pointer;
  }
  @media (min-width: 1800px) {
    .full{
      margin: 0 10%;
    }
    }

    .table-1-web{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

  </style>
  
  
  