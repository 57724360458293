export const getProductRoute = (product, route = null) => {
  console.log('product', product)
  console.log("route", route);
  let params = {
    ...route,
   }
  //  params.seller = product.seller.toLowerCase() || "seller"

   if (!params.location || params.location == 'tamilnadu') {
    params.location = product.market_name.replace(/\s+/g,'-').toLowerCase();
   }
  // if (!route?.categoryName) {
  //   console.log('route?.categoryName', route?.categoryName)

  console.log("route params in prod.js", params);
  if (product.product_id) {
    params.id = product.product_id;
   
    return {
        name:
            product.category_type == "live stocks"
                ? "livestockdetails"
                : "resultproduct",
        params,
    };
  } else if (product.equip_product_id) {
    params.id = product.equip_product_id;
   
    return { name: 'equipment', params };
  }
  return { name: 'fallbackRoute' };
}