<template>
  <div class="py-5 delete-acc" v-if="!accountDeleted">
   <div class="card">
    <div class="login-logo text-center">
      <img src="../../assets/img/vayal-new-logo.png" loading="lazy" alt="vayal-logo">
    </div>
    <h3 class="text-center">{{ translations.deleteAccount.acc }}</h3>
    <div class="mt-3 text-center">
      <h5>Are you sure you want to delete your account?</h5>
      <p class="text-left">Once you confirm, all content in this account will be permanently deleted. Deleted account cannot be recovered.</p>
      <p class="text-left">Please enter "Confirm"</p>
      <input type="text" v-model="confirmText" placeholder="Confirm" class="form-control my-3" required>
      <button @click="handleDeleteAccount" class="btn btn-danger" :disabled="confirmText !== 'Confirm'">{{ translations.deleteAccount.deleteAccountButton }}</button>
    </div>
  </div>
  
  </div>
     

  <div v-if="accountDeleted" class="py-5">
    <div class="card-1">
       <div class="login-logo text-center">
      <img src="../../assets/img/vayal-new-logo.png" loading="lazy" alt="logo">
    </div>
    <p class="text-center">All associated data will be permanently removed from our server within 7 to 10 days.</p>
    <p class="text-center"><a href="/">Go Home</a></p>

  </div>
     </div>
</template>


<script>
import axios from "axios";
import { en, ta } from "@/translations";

export default {
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      userId: this.$route.query.userId || null, // Retrieve userId from query params
      token: this.$route.query.token || null, // Retrieve token from query params
      confirmText: "", // To store the confirmation text entered by the user
      accountDeleted: false // Flag to indicate account deletion status
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
    async handleDeleteAccount() {
      if (this.confirmText !== 'Confirm') {
        alert('Confirmation text does not match.');
        return;
      }
      try {
        const response = await axios.delete(`https://vaiyal-app.herokuapp.com/user/${this.userId}`, {
          headers: {
            Authorization: `${this.token}`
          }
        });
        if (response.data.status == "success") {
          alert('Account deleted successfully');
          this.accountDeleted = true; // Set the flag to true to display the confirmation message
        } else {
          alert(response.data.msg);
        }
      } catch (error) {
        console.log('error:',error);
        alert(error.response.data.msg);
      }
    }
  },
  name: "DeleteAccount",
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    };
  }
};
</script>


<style scoped>
.delete-acc {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
.ml-3 {
  margin-left: 1rem;
}
.text-danger {
  color: red;
}

.login-logo {
  width: 50%;
  padding: 5%;
  margin-right: auto;
  margin-left: auto;
}

.login-logo img {
  width: 50%;
}
.card{
  background: #DEF4E4;
  padding: 5%;
 
}

.card-1{
  background: #DEF4E4;
  padding: 5%;
  width: 30%;
  font-size: 90%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;

}
@media (min-width: 320px) and (max-width: 660px) {
.delete-acc {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
}

@media (min-width: 670px) and (max-width: 768px) {
.delete-acc {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
}
</style>
