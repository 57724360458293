<template>
  <div class="top-bg">
    <div class="vayal-head">
      <h2>
        <span>{{ translations.vayal }}</span>
        {{ translations.agro }}
      </h2>
      <p class="agri-para">{{ translations.vayalDesc }}</p>
    </div>
    <div class="col-12 main-form">
      <div class="form-row">
        <div class="col-lg-5 col-md-4 col-sm-12">
          <div class="form-group">
            <div class="d-flex">
              <span class="ps-3 pt-2 locationicon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                  width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
                  <defs />
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                    <path
                      d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z"
                      style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                      transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    <path
                      d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z"
                      style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                      transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  </g>
                </svg>
              </span>

              <input placeholder="Tamilnadu"  class="form-control border-0 shadow-none form-control-lg"
                v-model="location" @input="updateSearchResults" @focus="updateSearchResults"
                @click="onSearchResultsClick" autocomplete="off" type="search" id="example-search-input4" />
              <ul v-if="searchResults.length" class="search-results">
                <li v-for="result in searchResults" :key="result.market_id"
                  @click="selectSearchResult(result, 'location')">
                  <span>
                    {{ selectedLanguage === "en"
                      ? result.market_name
                      : result.tn_name
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-4 col-sm-12">
          <div class="form-group">
            <input type="text" placeholder="Search Your 'Agriculture' Products & Equipments"
              class="form-control border-0 shadow-none form-control-lg" id="inputCity" v-model="product"
              @input="updateProductSearchResults" autocomplete="off" />

            <ul v-if="productSearchResults.length" class="search-results">
              <li v-for="result in productSearchResults" :key="result.category_id || result.sub_category_id"
                @click="selectSearchResult(result, 'product')">
                <span>
                  {{ selectedLanguage === "en"
                            ?
                        (result.sub_category_name || result.category_name) : (result.sub_category_tamil_name || result.category_tamil_name) 
                      }}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-12" @click="submitSearch" :disabled="product.trim() === ''">
          <button class="btn btn-success w-100">{{ translations.search }}</button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 sub-form home-category-icons">
      <div class="row mt-5 home-category-icons">
        <div class="col-lg-3 col-md-3 col-sm-6 router-cat home-category-icons" id="icon-mbl"
          @click="getModifiedCategoryLink('agriculture products', defaultLocationEn)">
          
          <div class="text-center icon-base home-category-icons">
            <img src="../../assets/img/banner/Agriculture-Products.svg" loading="lazy" alt="products" class="icon-img home-category-icons" />
            <p class="icon-text home-category-icons">{{ translations.products }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 router-cat home-category-icons" id="icon-mbl"
          @click="getModifiedCategoryLink('agriculture equipments', defaultLocationEn)">
          <div class="text-center icon-base home-category-icons">
            <img src="../../assets/img/banner/Agriculture-Equipments.svg" loading="lazy" alt="equipments" class="icon-img home-category-icons" />
            <p class="icon-text home-category-icons">{{ translations.equipments }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 router-cat home-category-icons" id="icon-mbl"
          @click="getModifiedCategoryLink('livestocks', defaultLocationEn)">
          <div class="text-center icon-base home-category-icons">
            <img src="../../assets/img/banner/cow-1.svg"  loading="lazy" alt="livestocks" class="icon-img home-category-icons" />
            <p class="icon-text home-category-icons">{{ translations.Livestocks }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 home-category-icons" id="icon-mbl">
          <router-link to="/market-price" class="router-cat home-category-icons">
            <div class="text-center icon-base home-category-icons">
              <img src="../../assets/img/banner/stability-1.svg" loading="lazy" alt="marketprice" class="icon-img home-category-icons" />
              <p class="icon-text home-category-icons">{{ translations.marketPrice }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useUserStore } from "@/stores/userStore";
import { en, ta } from "@/translations";

export default {
  name: "app",
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      routeName: "",
      params: "",
      marketId: 0,
      animate: false,
      isSidebarOpen: false,
      isHomePage: true,
      isNameSelected: false,
      isLocSelected: false,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: ""
    };
  },
  created() {
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
    this.checkHomePage();
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
    onClickOutside(event) {
      if (
        event.target.id !== "example-search-input4" &&
        !event.target.classList.contains("search-results") &&
        event.target.id !== "inputCity" &&
        !event.target.classList.contains("search-results-city")
      ) {
        this.searchResults = [];
        this.productSearchResults = [];
      }
    },
    setSearchParamsFromRoute() {
      if (this.$route.params.market_name) {
        this.location = this.$route.params.market_name.replace(/-/g, " ");
        this.defaultLocationEn = this.location;
      } else {
        this.defaultLocationEn = '',
        this.location = ''
      }
      console.log("*#&#*#&#&", this.defaultLocationEn);
      
    },
    checkHomePage() {
      this.isHomePage = this.$route.name !== "Home";
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    clearLocation() {
      this.location = "";
      this.defaultLocationEn = "";
      this.searchResults = [];
    },
    getModifiedCategoryLink(category, locationData) {
  if (!this.isLocSelected && this.defaultLocationEn !== "") {
    if (!this.$route.params.market_name && !this.isLocSelected) {
      alert("Enter a valid Location");
      return;
    }
  }
  this.$router.push({
    name: "FarmPage",
    params: {
      category: category?.replace(/\s+/g, "-"),
      location: locationData || "tamilnadu"
    }
  });
},

    onDocumentClick(event) {
      if (
        this.$refs.locationInputContainer &&
        !this.$refs.locationInputContainer.contains(event.target)
      ) {
        this.searchResults = [];
      }
      if (
        this.$refs.productInputContainer &&
        !this.$refs.productInputContainer.contains(event.target)
      ) {
        this.productSearchResults = [];
      }
    },
    async updateSearchResults() {
      this.filterNumbers();
      try {
        // console.log('updateSearchResults',this.product)
        this.defaultLocationEn = this.location;
        const response = await fetch(
          `https://vaiyal-app.herokuapp.com/get/location?search=${this.location}`
        );
        const data = await response.json();
        if (data.status === "success") {
          this.searchResults = data.data.filter(
            item => item.market_name !== undefined
          );
        } else {
          console.error("Error fetching search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },
     filterNumbers() {
      this.location = this.location.replace(/[0-9]/g, '');
    },
    async updateProductSearchResults() {
      this.filterOutNumbers();
      try {
        this.productSearchResults = []
        // console.log('updateProductSearchResults')
        this.defaultProductEn = this.product;
        const response = await fetch(
         `https://vaiyal-app.herokuapp.com/get/lists?search=${this.product}`
        );
        const data = await response.json();
        if (data.status === "success") {
          this.productSearchResults = data.data;
        } else {
          console.error("Error fetching product search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching product search results:", error);
      }
    },
    filterOutNumbers() {
      this.product = this.product.replace(/[0-9]/g, '');
    },
    onSearchResultsClick() {
      this.isNameSelected = false;
      this.isLocSelected = false;
    },
   selectSearchResult(result, field) {
  if (field === "location") {
    this.location = this.selectedLanguage === "en" ? result.market_name : result.tn_name;
    this.defaultLocationEn = result.market_name;
    this.defaultLocationTa = result.tn_name;
    this.marketId = result.market_id;
    this.isLocSelected = true;
    this.searchResults = [];
    this.$emit("updateMarketId", this.marketId); // Emit the market_id
  } else if (field === "product") {
    this.product = this.selectedLanguage === "en"
      ? result.sub_category_name ?? result.category_name
      : result.sub_category_tamil_name ?? result.category_tamil_name;
    this.defaultProductEn = result.sub_category_name ?? result.category_name;
    this.defaultProductTa = result.sub_category_tamil_name ?? result.category_tamil_name;
    this.isNameSelected = true;
    this.$route.params.category = result.category_type.replace(/\s+/g, '-').toLowerCase().replace('farm', 'agriculture').replace('live-stocks', 'livestocks');
    this.$route.params.product = result.sub_category_name?.replace(/\s+/g, '-')?.toLowerCase() ?? result.category_name.replace(/\s+/g, '-').toLowerCase();
    this.$route.params.categoryName = result.sub_category_name ? result.category_name?.replace(/\s+/g, '-')?.toLowerCase() : null;
  }
}
,
updateMarketId() {
  const userStore = useUserStore()
  userStore.updateMarketId(this.marketId);
},
    submitSearch() {
      if (
        (!this.isLocSelected && this.defaultLocationEn.trim() !== "") ||
        (this.defaultLocationEn.trim() === "" &&
          this.defaultProductEn.trim() === "")
      ) {
        alert(
          "Please select the correct location or product before searching."
        );
        return;
      }
      this.updateMarketId();
      this.$route.params.id = null;
      this.$route.params.category = 'agriculture-products'
      let params = { ...this.$route.params,
        location: (this.defaultLocationEn && this.defaultLocationEn.trim()) ? this.defaultLocationEn : "tamilnadu",
        product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-'): ''
        };
        params.market_name = params.location
      params.product ? params.product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : '';
      console.log('params in newbanner.vue:', params)
      const name = params.product || this.$route.name == 'resultproduct' || this.$route.name == 'equipment' || this.$route.name == 'livestockdetails' ? 'AllList' : this.$route.params.name
      this.$router.push({
        name: name,
        params: params,
      });
    },
    startAnimation() {
      this.$nextTick(() => {
        this.animate = true;
      });
    }
  },
  watch: {
    "$route.params": {
      handler: "setSearchParamsFromRoute",
      immediate: true,
      deep: true
    },
    selectedLanguage(newLang) {
      if (newLang === "en") {
        this.location = this.defaultLocationEn;
        this.product = this.defaultProductEn;
      } else if (newLang === "ta") {
        this.location = this.defaultLocationTa;
        this.product = this.defaultProductTa;
      }
    },
    $route: "checkHomePage"
  },
  mounted() {
    document.addEventListener("click", this.onDocumentClick);
    document.addEventListener("click", this.onClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
    document.removeEventListener("click", this.onClickOutside);
  }
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");

.top-bg {
  background-image: url(../../assets/img/banner/Mask-group.webp);
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.vayal-head {
  color: white;
  padding-top: 10%;
  text-align: center;
}

.vayal-head h2 {
  font-family: "Mukta";
  font-weight: 200;
}

.vayal-head h2 span {
  font-weight: 800;
}

.agri-para {
  font-family: "Mukta";
  font-size: 16px;
  font-weight: 100;
  padding-top: 1%;
  padding-bottom: 1%;
}

.main-form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.sub-form {
  margin-top: -1%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.locationicon {
  margin-right: -10%;
  z-index: 9999;
}

#example-search-input4 {
  z-index: 999;
  padding-left: 13% !important;
  border-radius: 5px !important;
  font-size: 14px;
  height: 45px;
}

input::placeholder {
  font-size: 14px !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;

  width: 15px;
  height: 15px;
  font-size: 12px;
  margin-left: 10px;
  background: linear-gradient(45deg,
      rgba(8, 255, 65, 0) 0%,
      rgba(130, 216, 30, 0) 43%,
      #7bb13f 45%,
      #7bb13f 55%,
      rgba(52, 153, 26, 0) 57%,
      rgba(50, 195, 31, 0) 100%),
    linear-gradient(135deg,
      transparent 0%,
      transparent 43%,
      #87e709 45%,
      #7bb13f 55%,
      transparent 57%,
      transparent 100%);
}


input[type="search"][value="dark"]::-webkit-search-cancel-button {
  background: linear-gradient(45deg,
      rgba(123, 177, 63) 0%,
      rgba(123, 177, 63) 43%,
      #fff 45%,
      #fff 55%,
      rgba(123, 177, 63) 57%,
      rgba(123, 177, 63) 100%),
    linear-gradient(135deg,
      transparent 0%,
      transparent 43%,
      #fff 45%,
      #fff 55%,
      transparent 57%,
      transparent 100%);
}

#inputCity {
  border-radius: 5px !important;
  font-size: 14px;
  height: 45px;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 10px;
  width: 95%;
  min-width: 200px;
  color: #16123f;
  background: #f9ffed;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  z-index: 9999;
  margin: 0px 0px;
}

.search-results li {
  cursor: pointer;
  padding: 3px 10px;
  font-size: 14px;
}

.search-results li:hover {
  cursor: pointer;
  color: #71a600;
  background: #fff;
}

.search-results::-webkit-scrollbar {
  width: 7px;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #71a600;
  border-radius: 5px;
}

.search-results::-webkit-scrollbar-track {
  background-color: #ffffff;
}

#example-search-input4::placeholder {
  font-size: 18px;
}

#inputCity::placeholder {
  font-size: 16px;
}

.btn-success {
  font-size: 14px;
  height: 45px;
  background-color: rgba(123, 177, 63, 1) !important;
  border-radius: 5px !important;
}

.clear-button {
  cursor: pointer;
  background: none;
  border: none;
  padding-top: 0;
  font-size: 18px;
  background: #71a600;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  z-index: 999;
  right: 10px;
  height: 30px;
  width: 30px;
  margin: 0;
  margin-top: 2.5%;
}

.icon-text {
  color: white;
  font-size: 14px;
  padding-top: 10%;
}

.icon-img {
  height: 50px;
}

.icon-base {
  padding: 10%;
  width: 130px;
  height: 130px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 3;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
}

.router-cat {
  text-decoration: none;
}

.icon-base:hover {
  background-image: linear-gradient(rgb(255, 255, 255, 0.2),
      rgb(255, 255, 255, 0.2));
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.cancel {
  color: red !important;
}

@media (max-width: 768px) {
  .main-form {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .sub-form {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .top-bg {
    background-image: url(../../assets/img/banner/Mask-group.webp);
    height: auto;
    background-position: center;
    background-size: cover;
  }

  .icon-base {
    padding: 10%;
    width: 100px;
    height: 100px;
    margin-bottom: 10%;
  }

  .main-form {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .sub-form {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5%;
  }

  .sub-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sub-form #icon-mbl {
    flex: 0 0 50%;
  }

  .icon-img {
    height: 30px; 
  }
}
</style>