<template>
  <div class="py-5 delete-acc">
    <div class="card text-center">
    <div class="login-logo text-center">
      <img src="../../assets/img/vayal-new-logo.png" loading="lazy" alt="vayal-new-logo">
    </div>
    <h3 class="text-center">{{ translations.deleteAccount.title }}</h3>
    <form v-if="!otpVerified" @submit.prevent="handleDeleteAccount">
      <div class="form-group text-left">
        <label for="phonenumber" >{{ translations.deleteAccount.mobileNumber }}</label>
        <input 
          type="search" 
          placeholder="Enter Your Mobile Number" 
          id="example-search-input4" 
          v-model="phonenumber" 
          class="form-control" 
          required 
          @input="validatePhoneNumber"
          :class="{ 'is-invalid': phoneError }"
        >
        <div v-if="phoneError" class="invalid-feedback">{{ phoneError }}</div>
      </div>
      <button 
        type="button" 
        @click="requestOtp" 
        class="btn btn-primary"
        :disabled="otpButtonDisabled"
      >{{ otpButtonText }}<span v-if="timer > 0" class="ml-1">in {{ timer }}s</span>
</button>
      <div v-if="otpRequested" class="form-group mt-3  text-left">
        <label for="otp">{{ translations.deleteAccount.enterOtp }}</label>
        <input type="text" id="otp" v-model="otp" class="form-control" required>
      </div>
      <div v-if="otpRequested" class="mt-3">
        <div>
          <button 
            type="button" 
            @click="verifyOtp" 
            class="btn btn-primary"
          >{{ translations.deleteAccount.verifyOtp }}</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import axios from "axios";
import { en, ta } from "@/translations";

export default {
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      phonenumber: "",
      otp: "",
      otpRequested: false,
      otpVerified: false,
      loginDetails: null,
      userId: null, // To store user ID
      token: null, // To store JWT token
      timer: 0, // Timer for OTP validity
      phoneError: "", // To store phone number validation error
      otpButtonText: "Request OTP", // Button text for OTP request
      otpButtonDisabled: false // OTP button disabled state
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
    validatePhoneNumber() {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(this.phonenumber)) {
        this.phoneError = "Please enter a valid 10-digit phone number.";
      } else {
        this.phoneError = "";
      }
    },
    async requestOtp() {
      if (this.phoneError) {
        alert(this.phoneError);
        return;
      }
      try {
        const response = await axios.post('https://vaiyal-app.herokuapp.com/login', {
          phonenumber: this.phonenumber
        });
        if (response.data.Status == "Success") {
          this.otpRequested = true;
          this.loginDetails = response.data.Details;
          this.startTimer();
          this.otpButtonText = "Resend OTP";
          this.otpButtonDisabled = true;
          setTimeout(() => {
            this.otpButtonDisabled = false;
          }, 30000); // Re-enable the button after 30 seconds
          alert('OTP sent successfully');
        } else {
          alert(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alert('Failed to send OTP');
      }
    },
    async verifyOtp() {
      try {
        const response = await axios.post('https://vaiyal-app.herokuapp.com/verifylogin', {
          phonenumber: this.phonenumber,
          otp: this.otp,
          Details: this.loginDetails
        });
        if (response.data.status == "success") {
          this.otpVerified = true;
          this.userId = response.data.data.userid;
          this.token = response.data.data.JwtToken;
          alert('OTP verified successfully');
          this.$router.push({
            name: "AccountDelete",
            query: { token: this.token, userId: this.userId }
          });
        } else {
          alert(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alert('Failed to verify OTP');
      }
    },
    startTimer() {
      this.timer = 30;
      const countdown = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(countdown);
        }
      }, 1000);
    }
  },
  name: "LoginAccount",
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    };
  }
};
</script>

<style scoped>
.delete-acc {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
.ml-3 {
  margin-left: 1rem;
}
.text-danger {
  color: red;
}
.is-invalid {
  border-color: red;
}
.invalid-feedback {
  color: red;
  font-size: 0.875em;
}

.login-logo{
  width: 30%;
  margin-right: auto;
  margin-left: auto;
}

.login-logo img{
  width: 50%;
  padding: 5%;
}

.card{
  background: #DEF4E4;
  padding: 5%;
  margin-top: 10%;
}
@media (min-width: 320px) and (max-width: 660px) {
.delete-acc {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
}
@media (min-width: 670px) and (max-width: 768px) {
.delete-acc {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
}
</style>
