<template>
  

  <div class="recentpricefull mb-4">
    <div class="containers">
      <div >
        <div class=" page py-5">    
          
           <h1 class="text-center text-capitalize mb-0">
          
  {{ selectedLanguage === "en" 
      ? this.tamildata && this.tamildata.category_name 
        ? translations.history_of + " " + this.tamildata.category_name 
        : '' 
      : this.tamildata && this.tamildata.tn_category 
        ? this.tamildata.tn_category + " " + translations.history_of 
        : ''
  }}
  <small v-if="selectedLanguage === 'en' 
              ? this.tamildata && this.tamildata.sub_category_name 
              : this.tamildata && this.tamildata.tn_sub_name">
    ({{ selectedLanguage === "en" 
        ? this.tamildata.sub_category_name 
          ? this.tamildata.sub_category_name 
          : this.tamildata.category_name 
        : this.tamildata.tn_sub_name 
          ? this.tamildata.tn_sub_name 
          : this.tamildata.tn_category 
    }})
  </small>
</h1>
        <div class="   pt-2">
          <!-- <div class="sharphead text-capitalize text-center">
            <h3> {{  this.localName }}</h3>
          </div> -->
          <div class="searchitem  m-0 p-0">
            <div class="searchbox  ">
              <div class="dist mx-2">
                <h6 class="">{{ translations.selectdist }}</h6>

                <select class="selec " v-model="selectedMarketId" id="l1">
                   <option value="0" disabled >{{ translations.selectDist1 }}</option>
                  <option v-for="market in markets" :key="market.market_id" :value="market.market_id" class="listoption">
                    {{ selectedLanguage === 'en' ? market.market_name : market.tn_name }}
                  </option>
                </select>
              </div>
              <div class="town mx-2">
                <h6 class="t"> {{ translations.selecttown }}</h6>
                <select class="selec " v-model="selectedMarketPlaceId">
                  <option value="0" disabled >{{ translations.selectCity }}</option>
                  <option v-for="place in places" :key="place.market_place_id" :value="place.market_place_id">
                    {{ selectedLanguage === 'en' ? place.place : place.tn_place }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class=" border-0 view  text-center mt-">
            <!-- <div class="sharphead ">
              <h3>{{ translations.recentprice }}</h3>
            </div> -->
            <button class="text-center btns ms-3 py-2 px-3" @click="toggleView">
              <i v-if="isPowerOne" class="fas fa-chart-line me-2"></i>
              <i v-else  class="fa fa-table me-2"></i>
              {{ isPowerOne ? 'Graph' : 'Table' }}
            </button>
          
          </div>
              <h6 class="bread-crubs text-uppercase text-center mt-4" >
  <a href="/">Home</a>

  <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>

  <a href="/market-price">Market Price</a>

  <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>

    <router-link :to="`/market-price/tamilnadu/${this.$route.params.categoryName}-market-price${this.$route.params.location}/${this.$route.params.city ?? ''}`">
                  {{ this.$route.params.categoryName }}
   </router-link>

</h6>  
        </div>
      </div>


     

        <!-- Display either powerone or powertwo based on the current state -->
        <div class="full" :class="{ 'powerone': isPowerOne, 'powertwo': !isPowerOne }">
          <!-- PowerOne Section -->
          <div v-if="isLoading" class="mt-5">
            {{console.log("v-if",this.isLoading)}}
            <!-- Shimmer effect for loading -->
            <div v-for="index in 6" :key="index" class="container" style="width: 60%">
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
          </div>
          <div v-if="isPowerOne && recentPriceData.length">
           
       <div class="d-sm-none">
  <div class="table table-responsive w-100 text-center px-md-5 px-sm-2 px-2">
    <table class="table">
      <thead>
        <tr class="text-nowrap">
          <!-- <th>{{translations.category}}</th> -->
          <th>{{ translations.date }}</th>
          <th>{{ translations.price }}/{{ translations.quantity }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-nowrap" v-for="(item, index) in recentPriceData" :key="index">
          <!-- <td>{{  this.localName }}</td> -->
          <td>{{ formatCreatedDate(item.created) }}</td>
          <td><i class="fa fa-inr" aria-hidden="true"></i> {{ item.price }}/{{ selectedLanguage === "en" ? item.quantity_type : item.quantity_type_tamil }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="table-1-web d-none d-sm-block ">
  <!-- Your regular table code for larger screens -->
  <div class="table table-responsive w-100 text-center  px-sm-2 px-2">
    <table class="table">
      <thead>
        <tr class="text-nowrap">
          <th class="cate-th">{{translations.category}}</th>
          <th>{{ translations.date }}</th>
          <th>{{ translations.price }}</th>
          <th>{{ translations.quantity }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-nowrap" v-for="(item, index) in recentPriceData" :key="index">
          <td>{{  selectedLanguage === "en" ? tamildata.sub_category_name || tamildata.category_name : tamildata.tn_sub_name || tamildata.tn_category }}</td>
          <td>{{ formatCreatedDate(item.created) }}</td>
          <td><i class="fa fa-inr" aria-hidden="true"></i> {{ item.price }}</td>
          <td>{{ selectedLanguage === "en" ? item.quantity_type : item.quantity_type_tamil }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

          </div>
                <div v-else-if="recentPriceData.length" class="powertwo">
         
  
            <CanvasJSChart class="chart container" :options="options" :style="styleOptions" @chart-ref="chartInstance" />
            <div class="hide">
              
            </div>

          </div>
          <div v-else class="mt-5">
            {{console.log("v-if",this.isLoading)}}
            <!-- Shimmer effect for loading -->
            <div v-for="index in 6" :key="index" class="container" style="width: 60%">
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
          </div>
        </div>
  </div>
    </div>

  </div>
</template>
  
<script>
import { en, ta } from "@/translations";
import { useItemStore, useUserStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";
export default {
  props: ["selectedLanguage"],   // Receive the selectedLanguage prop from App.vue
  data() {
    return {
      isPowerOne: true, // Initial state

      localCategoryName:null,
      localName:null,
      markets: [],
      places: [],
      selectedMarketId: 0,
      selectedMarketPlaceId: 0,
      recentPriceData: [],    // Use an array to store recent price data
      isLoading: false,
      isdetailsLoading:false,
      chart: null,
      tamildata:null,
      options: {
      //   toolBar: {
      //   // Set showDefaultButtons to false to hide download and print icons
      //   showDefaultButtons: false
      // },
        animationEnabled: true,
        exportEnabled: false,
        // theme: "light1",
        backgroundColor: "transparent",
        title: {
          text: (this.$route.params.name).replace(/-/g,' '),
          padding: 15,  // Add some padding to the title
          fontFamily: "Mukta",
        },
        axisX: {
          title: `\n History price updated by near-by vendors`,
  valueFormatString: "DD/MM", // Display date in the desired format
  labelTextAlign: "center",
  labelAngle: 0,
  fontSize: 10,
  titleFontColor: "green", // Customize title font color if needed
  titleFontSize: 14,
},
        axisY: {
          title: "",
          fontSize: 10,
          valueFormatString: "₹##,##,##0",
        },

        data: [
          {
            type: "line",
            lineColor: "green",
            markerColor: "green",
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "₹##,##,##0.00", // Format for the y-axis
            dataPoints: []
          }
        ],
      },
      styleOptions: {
        width: "100%",
        height: "360px",
        overflow: "hidden",
      }
    };
  },
  computed: {
    ...mapState(useItemStore, ['categoryId', 'subCategoryId']),
    ...mapState(useUserStore, ['marketId', 'marketPlaceId','updateMarketPlaceIdByCity']),
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  async created() {
    await this.updateItemId(this.$route.params.name.replace(/-/g,' '),'sub_category_name',true);
    await this.updateMarketIdByDist(this.$route.params.location.split('-')[1].replace(/-/g,' '))
    await this.updateMarketPlaceIdByCity(this.$route.params.city.replace(/-/g,' '))
    this.fetchMarkets();

  },
mounted() {
    this.localCategoryName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' '));
    
    if (this.$route.params.name === "") {
        this.localName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' '));
    } else {
        this.localName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' '));
    }
},

 watch: {
 
  selectedMarketId(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.selectedMarketPlaceId = 0;
    }
    this.updateMarketId(this.selectedMarketId)
  },
  selectedMarketPlaceId: {
    handler() {
      this.updateMarketPlaceId(this.selectedMarketPlaceId);
      this.fetchRecentPrice();
    },
    immediate: true
  },
  marketId: {
    handler(newVal, oldVal) {
      console.log('mId changed from' + oldVal + 'to' + newVal)
      if (newVal !== oldVal && this.marketId != '0') {
        console.log('changing dist params:')
        if (this.selectedMarketId && this.selectedMarketId !== "") {
        this.fetchPlaces();
        }
      }
    }
  },
  marketPlaceId: {
    handler(newVal, oldVal) {
      console.log('mpId changed from' + oldVal + 'to' + newVal)
      if (newVal !== oldVal && this.marketId != '0') {
        console.log('changing city params:')
        
        let params = { ...this.$route.params }
        for (let district of this.markets) {
          if (district.market_id == this.marketId) {
            params.location = district?.market_name?.replace(/\s+/g,'-')?.toLowerCase()
            console.log('setting location in params:', params.location)
            break;
          }
        }

        if (this.selectedPlace != '0') {
          for (let city of this.places) {
            if (city.market_place_id == this.marketPlaceId) {
              params.city = city?.place?.replace(/\s+/g,'-')?.toLowerCase()
              console.log('setting city in params:', params.city)
              break;
            }
          }
        } else params.city = ''
        params.location = params.location[0] == '-' ? params.location : `-${params.location}`, 
        this.$router.push({
          name: 'AllRecent',
          params
        })
      }
    }
  },
  categoryId: {
    handler(newVal, oldVal) {
      console.log('catId changed from' + oldVal + 'to' + newVal)
    }
  },
  subCategoryId: {
    handler(newVal, oldVal) {
      console.log('subcatId changed from' + oldVal + 'to' + newVal)
    }
  }
}
,
  methods: {
    ...mapActions(useItemStore, ['updateItemId','updateMarketCatId']),
    ...mapActions(useUserStore, ['updateMarketId','updateMarketIdByDist','updateMarketPlaceId']),
    toggleView() {
      this.isPowerOne = !this.isPowerOne;
    },
    chartInstance(chart) {
      this.chart = chart;
    },
    formatCreatedDate(dateString) {
      // Convert the date string to a Date object
      const date = new Date(dateString);

      // Get the day, month, and year
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
      const year = date.getUTCFullYear();
      // Format the final string as "dd-mm-yyyy"
      return `${day}-${month}-${year}`;
    },

    fetchMarkets() {
  const selectproduct = decodeURIComponent('market price');
  const categoryid = this.categoryId;
  const subcategoryid = this.subCategoryId;
  const marketIdFromURL = this.marketId || null;

  const selectproductMapping = {
    "farm products": "product",
    "livestocks": "product",
    "farm equipments": "equipment",
    "market price": "market"
  };

  const mappedSelectProduct = selectproductMapping[selectproduct] || selectproduct;

  const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=${mappedSelectProduct}&${subcategoryid ? `subcategoryid=${subcategoryid}` : categoryid ?`categoryid=${categoryid}`:''}&recent=true`;

  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      this.markets = data.data;
      console.log("this.market", this.markets);

      // Redirect to error page if no markets are found
      if (!this.markets || this.markets.length === 0) {
        this.$router.replace({ path: "/error" });
        return;
      }

      // Check if marketIdFromURL exists and is valid
      if (marketIdFromURL && this.markets.some(market => market.market_id === marketIdFromURL)) {
        this.selectedMarketId = marketIdFromURL;
        this.updateMarketId(marketIdFromURL);
      } else {
        // Default to "Select City" option if no valid market ID is found
        this.selectedMarketId = 0;
      }

      console.log('Selected Market ID:', this.selectedMarketId);
      this.fetchPlaces(); // Fetch additional data related to the selected market
    })
    .catch(error => {
      console.error('Error fetching markets:', error);
    });
},


    fetchPlaces() {
      const selectproduct =  decodeURIComponent('market price');
      const categoryid = this.categoryId;
      const subcategoryid = this.subCategoryId;
      const marketplaceIdFromURL = this.marketPlaceId; // Assuming the marketplaceid is in the URL

      const selectproductMapping = {
        "farm products": "product",
        "livestocks": "product",
        "farm equipments": "equipment",
        "market price": "market"
      };
      const mappedSelectProduct = selectproductMapping[selectproduct] || selectproduct;

      const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=${mappedSelectProduct}&${subcategoryid ? `subcategoryid=${subcategoryid}` : categoryid ?`categoryid=${categoryid}`:''}&recent=true&marketid=${this.selectedMarketId}`;

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          this.places = data.data;

          if (marketplaceIdFromURL && this.places.some(place => place.market_place_id === marketplaceIdFromURL)) {
            this.selectedMarketPlaceId = marketplaceIdFromURL; // Set the selectedMarketPlaceId from the URL
            this.updateMarketPlaceId(marketplaceIdFromURL)
          } else {
            const selectedCity = this.$route.params.city.replace(/-/g, ' ')
            const selectedPlace = this.places.find(place => 
              place.place.toLowerCase() === selectedCity 
            );
            // Check if a place is found and handle accordingly
            if (selectedPlace) {
              this.selectedMarketPlaceId = selectedPlace.market_place_id;
              this.updateMarketPlaceId(this.selectedMarketPlaceId);
            }
          }
          console.log('mpId set inside:', this.marketPlaceId)

          this.fetchRecentPrice();
        })
        .catch(error => {
          console.error('Error fetching places:', error);
        });
      
    },

    fetchRecentPrice() {
      const payload = {
        subcategoryid: this.subCategoryId,
        categoryid: this.subCategoryId ? undefined : this.categoryId,
        marketplaceid: this.marketPlaceId
      };
      // console.log(`payload: `, this.$route.params.categoryId);
      this.isLoading = true;
      this.isdetailsLoading=true;

      fetch('https://vaiyal-app.herokuapp.com/subcategory/getrecentprice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => {
          
            console.log("apiUrl",data.details.category_name)
          this.recentPriceData = data.data; 
          this.tamildata = data.details;
          this.subData = data.details.sub_category_name;
          console.log("this.subData",this.recentPriceData)
          this.updateChartData(); 
           if (!this.recentPriceData || this.recentPriceData.length === 0) {
            this.$router.replace({ path: "/error" });
          }
         
            
            const apiCategoryName = data.details.category_name;
            const currentCategoryName = this.$route.params.categoryName;

            let needsRouteUpdate = false;
            let newRouteParams = { ...this.$route.params }; 
        
            if (apiCategoryName && apiCategoryName !== currentCategoryName) {
              newRouteParams.categoryName = apiCategoryName.replace(/\s+/g, '-').toLowerCase();
              needsRouteUpdate = true;
            }

             if (needsRouteUpdate) {
              this.$router.replace({ ...this.$route, params: newRouteParams });
            }
        })
        .catch(error => {
        
          console.error('Error fetching recent price:', error);
        })
        .finally(() => {
          this.isLoading = false;
          this.isdetailsLoading=false;
        });
    },

    // Add a method to update the chart data
    updateChartData() {
      // Prepare the dataPoints array for the chart
      const dataPoints = this.recentPriceData.map(item => ({
        x: new Date(item.created), // Convert created date to a Date object
        y: parseFloat(item.price) // Parse price as a float
      }));

      // Set the dataPoints in the chart options
      this.options.data[0].dataPoints = dataPoints;

      // Render the chart
      if (this.chart) {
        this.chart.render();
      }
    }
  }
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
body{
  font-family: "Mukta" ;
}
h1{
  color: #71A600;
}
.bread-crubs{
  color: #238601;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.bread-crubs a{
   text-decoration: none !important;
   color: #238601;
}
.bread-crubs .fa-angle-right{
  color: #238601;
}
.page{
  
  background-image: url("../../assets/img/drive/Detail-Banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* .breadcrumb {
    text-decoration: none;
    color: #238601;
} */

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #4E7300;
}

.loading-spinner i {
  font-size: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* -----main----- */
.searchitem {
  margin: 0px 0px 10px 0px;
  padding: 20px 30px 10px 30px;
  border-radius: 6px;
  font-family: "Mukta";
}

.catename h3 {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 128, 0, 0.9);
  margin: 0px 0px;
  font-family: "Mukta" ;
}

.catename2 h3 {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgba(0, 128, 0, 0.9);
  margin: 0px 0px 0px 0px;
  font-family: "Mukta" ;
  padding-left: 20px;
}
.searchbox{
  display: flex;
  justify-content: center;
}
.searchbox h6 {
  font-size: 14px;
  color: rgba(70, 67, 67, 0.8);
}

.searchbox select {
  width: 100%;
  min-width: 200px;
  height: 40px;
  padding: 0px 10px;
  color: rgba(70, 67, 67, 0.8);
  border: .5px solid rgba(163, 159, 159, 0.8);
}

.dist,
.town {
  margin: 20px 0px;
}


select.town {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../../assets/img/Icons/arrow_drop.png');
  background-size: 26px 26px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding: 8px 30px 8px 8px;
  border: 1px solid #ccc;
  width: auto;
}

.dist select option:hover, .town select option:hover {
  background-color: rgba(0, 128, 0, 0.9);
  color: #ffffff;
}
.displayitem {
  margin: 0px 0px 100px 0px;
  padding: 20px 0px 50px 0px;
  border-radius: 6px;
  font-family: "Mukta" ;
  height: 70px;
}

.sharphead {
  text-transform: capitalize;
  margin: 0px 0px 0px 0px;
}

.sharphead h3 {
  font-size: 24px;
  color: rgba(28, 28, 28, 0.8);
}

.createprice {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
}

.createprice p {
  font-size: 14px;
}

/* ------------- */
.listdate {
  margin-top: -20px;
  margin-bottom: 50px;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 10px 0px;
  padding: 7px 15px 0px 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

}

.date h5 {
  font-size: 16px;
  font-family: "Mukta" ;
}

.created {
  font-family: "Mukta" ;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  height: 335px;
}

.nodata img {
  margin-top: 50px;
  width: 400px;

}

.marketname {
  font-size: 18px;
}

.colourmaker .date {
  position: relative;
}

.colourmaker .date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(odd)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(even)::before {
  background-color: rgba(145, 155, 5, 0.9);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  z-index: -1;
}

.material-symbols-outlined {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9);
  padding-right: 5px;
}

.boxdate {
  height: 62vh;
  overflow: auto;
  padding: 0px 20px;

}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb; /* */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 128, 0, 0.9);
}

.containers {
  margin: 0px 0px;
}

select.selec {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  background-image: url('../../assets/img/Icons/arrow_drop.png');
  background-size: 26px 26px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding: 8px 30px 8px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
select.selec  option{
  color: #334b35;
}
select.selec  option:hover{
  color: #334b35;
}
select.selec:focus {
  outline: none;

}

@media screen and (max-device-width: 768px) {
  .containers {
    margin: 0px 0px;
  }

  .sharphead {
    margin: 10px 0px 10px 0px;
  }

  .searchitem {
    margin: 10px 0px 0px 0px;
    padding: 20px 30px 20px 30px;
    border-radius: 6px;
    font-family: "Raleway", sans-serif;
  }

  .sharphead h3 {
    margin-top: 20px;
    font-size: 16px;
    color: rgba(70, 67, 67, 0.8);
  }

  .nodata img {
    width: 300px;
  }

 

}

.chart {
  position: relative;

}

.canvasjs-chart-toolbar button{
  display: none;
}
.dummy {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.hide{
  background: #fff;
height: -10.5px;
margin-top: -10.5px;
z-index: 99999;
position: absolute;
}
.table th {
  background-color: #f2f2f2;
  color: #16123f;
}

td a {
  text-decoration: none;
  color: green;
}

td a:hover {
  color: darkgreen;
}

td small {
  color: gray;
}

.table-container {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  border-top: 1px solid #71A600;
  border-bottom: 1px solid #71A600;
  padding: 10px 40px;
  text-align: left;
  width: 100%;
}
.table th{
  background: #71A600;
  color: #fff;
}
.table td{
  background: #f9ffed;
}
@media (min-width: 300px) and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .table th{
  background: #71A600;
  color: #fff;
  font-size: 14px;
      padding:10px !important;

}
.table td{
  background: #f9ffed;
    padding:2%  !important;
 font-size: 12px;
}

.page {
  background-image: url("../../assets/img/drive/Detail-Banner-mbl.webp");
  background-size: cover;
  position: relative;
  padding: 2%;
}

.table-1-web .table{
  width: 100% !important;
  
}



}

.btns{
  background: #71A600;
  color: white;
  font-family: 'Mukta';
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: none;
}
.view button{
  font-size: 14px;
}
@media (min-width: 300px) and (max-width: 470px){
  .searchbox{
    display: block;
  }
}

@media (min-width: 1800px) {
  .full{
    margin: 0 15%;
  }
}


.table-1-web{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

</style>