<template>
  <article>
    <h1>We&rsquo;ll be back soon!</h1>
    <div>
      <p>{{ maintenanceMsg }}</p>
    </div>
  </article>
</template>

<script>

export default {
  name: "MaintenancePage",
    data() {
      return {
        showMaintenance: false,
        maintenanceMsg: '',
      };
    },
    methods: {
      closePopup() {
        this.showMaintenance = false;
      },
      fetchPopup() {
        // Fetch data from the API
        fetch('https://vaiyal-app.herokuapp.com/maintenance?type=maintenance')
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success' && data.data.isactive) {
              
                this.maintenanceMsg = data.data.msg;
                this.showMaintenance = true;
              
            } else {
              console.error('Failed to fetch data:', data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
    },
    mounted() {
      this.fetchPopup();
    },
  };
</script>

<style scoped>
body {
  text-align: center;
  padding: 150px;
}
h1 {
  font-size: 50px;
}
body {
  font: 20px Helvetica, sans-serif;
  color: #333;
}
article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
  margin-top: 10%;
}
a {
  color: #dc8100;
  text-decoration: none;
}
a:hover {
  color: #333;
  text-decoration: none;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 768px) {
}
@media only screen and (max-device-width: 480px) {

    article {
  display: block;
  text-align: center;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10%;
}
    h1 {
  font-size: 20px;
}
p{
    font-size: 16px;
    width: 100%;
}
}
</style>
