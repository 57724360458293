<template>
  <div class="m-0 p-0">
    <!-- <h6 class="head p-2 m-0">Advertisement</h6> -->
    <div v-if="recentProductsfarm && recentProductsfarm.length > 0" class="card m-0 p-0">
      <div class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div
            v-for="(product, index) in recentProductsfarm"
            :key="product.id"
            :class="{ 'carousel-item': true, 'active': index === 0 }"
          >
            <div class="image-container center">
              <!-- Use align-self-end for bottom alignment -->
              <img
                v-if="product.images && product.images.length > 0"
                :src="getCurrentImage(product)"
                class="d-block w-100"
                alt="..."
              />
              <div class="gradient-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body text-capitalize row gx-5">
        <!-- mt-auto pushes card-body to the bottom -->
        <div class="col-md-7 col-lg-7 col-sm-12 " >
          <h6
            class="card-titlelite text-capitalize m-0 p-0"
          >{{ getFirstTwoWords(recentProductsfarm[0].title) }}</h6>
          <h5
            class="card-title text-uppercase m-0 p-0"
          >{{ getRemainingWords(recentProductsfarm[0].title) }}</h5>
          <h4 class="sub">{{recentProductsfarm[0].sub_title}}</h4>
          <p class="card-text py-2">{{ recentProductsfarm[0].description }}</p>
          <!-- Assuming description is the same for all products -->
          <div class="links d-md-flex m-sm-2 justify-content-md-start">
            <button class="contact px-4 py-0 m-0 my-2 mx-1" @click="makeCall">Contact Now</button>
            <button class="link px-4 py-0 m-0 mx-1 my-2">
              <a class="m-0 p-0" :href="recentProductsfarm[0].links" target="_blank">Show More</a>
            </button>
            
            <button class="contact px-4 py-0 m-0 my-2 mx-1">
              <a class="text-light text-decoration-none" :href="'mailto:' + recentProductsfarm[0].email">Message Now</a>
            </button>
          </div>
        </div>

        <div class="col-md-5 col-lg-5 col-sm-12 right  ">
          
          <div class="dis_off ">
<div class="dis_offs text-center ms-3"  v-if="recentProductsfarm[0].dis_off && recentProductsfarm[0].dis_off.length > 0">
<h4 class="align-middle py-3 px-2 text-center overflow-hidden">{{recentProductsfarm[0].dis_off}}</h4>
</div>
<div v-else>
  <p> </p>
</div>
          </div>
          <div class="image-one ">
            <img
              v-if="recentProductsfarm[0].add_images && recentProductsfarm[0].add_images.length > 0"
              :src="recentProductsfarm[0].add_images[0].value"
              class=" first"
              alt="First Additional Image"
            />
            <div v-else>
              <p> </p>
            </div>
          </div>

          <div class=" image-two">
            <img
              v-if="recentProductsfarm[0].add_images && recentProductsfarm[0].add_images.length > 1"
              :src="recentProductsfarm[0].add_images[1].value"
              class="d-block second"
              alt="Second Additional Image"
            />
            <div v-else>
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      market: [],
      recentProductsfarm: [],
      currentImageIndex: 0
    };
  },
  mounted() {
    this.fetchData();
    // Update the image every 5 seconds
    setInterval(() => {
      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.getImageCount();
    }, 5000);
  },
  methods: {
    makeCall() {
      const contactNumber = this.recentProductsfarm[0].contacts;

      window.location.href = "tel:" + contactNumber;
    },
    getFirstTwoWords(title) {
      const words = title.split(" ");
      return words.slice(0, 1).join(" ");
    },
    getRemainingWords(title) {
      const words = title.split(" ");
      return words.slice(1).join(" ");
    },
    fetchData() {
      fetch("https://vaiyal-app.herokuapp.com/get/adimage")
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            this.recentProductsfarm = data.data;
            // console.log(this.recentProductsfarm); 
            // Check the data in the console
          } else {
            console.error("Failed to fetch recent product data:", data.msg);
          }
        })
        .catch(error => {
          console.error("Error fetching recent product data:", error);
        });
    },

    getCurrentImage(product) {
      if (product.images && product.images.length > 0) {
        const index = Math.min(
          Math.max(0, this.currentImageIndex),
          product.images.length - 1
        );
        return product.images[index].value;
      }
      return ""; // Default image or handle no data case
    },
    getImageCount() {
      if (this.recentProductsfarm.length > 0) {
        return this.recentProductsfarm[0].images.length;
      }
      return 0;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed&family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");
.carousel img {
  object-fit: cover;
  margin: auto 0;
  height: 90vh;
  background-color: #f1f1f1;
  width: 100%;
}
.card {
  border: 1px solid #f1f1f1;
  width: 100%;
}
.card-body {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.card-title {
  color: #8cce00;
  font-family: "Mukta";
  font-size: 77px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  line-height: 90%;
}
.card-titlelite {
  color: #ffd228;
  font-family: "Satisfy";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-text {
  color: #fff;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: 129%; /* 25.8px */
}
.sub {
  color: #fff;
  font-family: "Mukta";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 178.261% */
  letter-spacing: 5px;
}
.contact {
  border-radius: 25px;
  background: #dea401;
  color: #fff;
  border: none;
  font-family: "Mukta";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
  pointer-events: none; /* Allows interactions with the image beneath the overlay */
}
.link {
  border-radius: 25px;
  border: 2px solid #fff;
  background: transparent;
}
.link a {
  color: #fff;
  background: transparent;
  font-family: "Mukta";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.link a:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.links p {
  font-size: 14px;
  color: #fff;
}
.links p a {
  color: white;
  text-decoration: none;
}
.links p a:hover {
  color: #f1f1f1;
  text-decoration: none;
}
.right .first {
  width: 300px;
  height: 300px;
z-index: 999;
border-radius: 50%; 
object-fit: cover;
}
.right .second{
  width: 200px;
  height: 200px;
  position: absolute;
  left: 30%;
  top: 200px;
  z-index: 99999;
  border-radius: 50%; 
  object-fit: cover;

}
.dis_offs{
  position: absolute;
z-index: 99999;
left: -30px;
}
.dis_offs h4{
  position: absolute;
z-index: 99999;
background: white;
border-radius: 50%;
width: 90px;
height: 90px;
font-size: 3vmin;
color: #8cce00;
font-weight: 800;
}
@media screen and (min-width: 1800px) {
  .right .first {
    width: 300px;
    height: 300px;

  }
  .right .second{
    width: 200px;
    height: 200px;
        left: 25%;
    top: 200px;
  }
  .dis_offs h4{
  z-index: 99999;
  width: 140px;
  height: 140px;
  font-weight: 1200;
  }
}
@media screen and (max-width: 1799px) {
  .right .first {
    width: 300px;
    height: 300px;

  }
  .right .second{
    width: 200px;
    height: 200px;
        left: 25%;
    top: 200px;
  }
  .dis_offs h4{
  z-index: 99999;
  width: 140px;
  height: 140px;
  font-weight: 1200;
  }
}
@media screen and (max-width: 1440px) {
  .right .first {
    width: 300px;
    height: 300px;  }
  .right .second{
    width: 200px;
    height: 200px;    left: 35%;
    top: 140px;
  }
  .dis_offs h4{
  z-index: 99999;
  width: 90px;
  height: 90px;
  font-weight: 1200;
  }
}
@media screen and (max-width: 1200px) {
  .right .first {
    width: 300px;
    height: 300px;  }
  .right .second{
    width: 200px;
    height: 200px;
        left: 30%;
    top: 120px;
  }
  .dis_offs h4{
  z-index: 99999;
  width: 140px;
  height: 140px;
  font-weight: 1200;
  }
}
@media screen and (max-width: 1000px) {
  .right .first {
    width: 280px;
    height: 280px;  }
  .right .second{
    width: 180px;
    height: 180px;    left: 35%;
    top: 150px;
  }
  .dis_offs h4{
    z-index: 99999;
    width: 140px;
    height: 140px;
    font-weight: 1200;
    }
  .card-body {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .card-titlelite {
    margin: 10px 0px;
  }
  .card-title {
    font-size: 35px;
    margin: 10px 0px;
  }
  .card-text {
    font-size: 14px;
    margin: 10px 0px;
  }
  .sub {
    font-size: 16px;
  }
  .right .first {
    width: 220px;
    height: 220px; 
    top: 10px;

    left: 30%;

   }
  .right .second{
    width: 120px;
    height: 120px;   
    top: 100px;
    left: 60%;

  }
  .dis_offs h4{
    z-index: 99999;
    width: 75px;
    height: 75px;
    font-weight: 1200;
    font-size: 4vmin;
    left: 30%;
    top: 10px;

    }

  .card-body {
    width: 100%;
    overflow: scroll;
    margin: 0;
    position: absolute;
    top: 10%;
    height: 90vh;
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  .carousel{
    width: 100%;
    overflow: scroll; 
  }
}
@media (min-width: 1800px) {
  .carousel img {
    object-fit: cover;
    margin: auto 0;
    height: 60vh;
    background-color: #f1f1f1;
    width: 100%;
  }
  }
</style>
