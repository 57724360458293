<template>
  <div class="homepro">
    <PopUp :selectedLanguage="selectedLanguage" />
    <NewBanner :selectedLanguage="selectedLanguage" @updateMarketId="setMarketId" />
    <RecommendPage
      :selectedLanguage="selectedLanguage"
      :marketId="userStore.marketId"
      :marketName="$route.params.market_name"
    />
    <AdVayal :selectedLanguage="selectedLanguage" />
    <PriceMarketPage
      :selectedLanguage="selectedLanguage"
      :marketId="userStore.marketId"
      :marketName="$route.params.market_name"
    />
    <div class="ho">
      <HomeBlog :selectedLanguage="selectedLanguage" />
      <OffersPage :selectedLanguage="selectedLanguage" />
    </div>
    <div v-show="showBackToTop" class="back-to-top" @click="scrollToTop">
      <i class="fa fa-arrow-up"></i>
    </div>
  </div>
</template>

<script>
import { en, ta } from "@/translations";
import HomeBlog from "@/components/HomeBlog.vue";
// import Nav from "@/components/homeaddon/Nav.vue";
import RecommendPage from "@/components/RecommendPage.vue";  
import NewBanner from "@/components/homeaddon/NewBanner.vue";
import OffersPage from "@/components/homeaddon/OffersPage.vue";
// import BottomAd from "@/components/homeaddon/BottomAd.vue";
import AdVayal from "@/components/homeaddon/AdVayal.vue";
import PriceMarketPage from '@/components/PriceMarketPage.vue';
import PopUp from '@/components/homeaddon/PopUp.vue';
import { useUserStore } from '../stores/userStore'
import "bootstrap/dist/css/bootstrap.css";
export default {
  setup () {
    const userStore = useUserStore()
    
    console.log('userStore: ',userStore.marketPlaceId)
    return { userStore }
  },
  created () {
    if (!this.$route.params.location || this.$route.params.location == 'tamilnadu') {
      this.setMarketId(null)
    }
  },
  name: "HomePage",
  props: ["selectedLanguage"],
    components: {
    PopUp,
    NewBanner,
    HomeBlog,
    OffersPage,
    // BottomAd,
    RecommendPage,
    PriceMarketPage,
    AdVayal,
  },
  data() {
    return {
      showBackToTop: false,
      marketId: "" 
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
    setMarketId(marketId) {
      this.marketId = marketId;
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 600;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  // beforeCreate() {
  //   console.log('homepage beforeCreate hook!')
  //   if (this.$route.params.location == 'market-price') {
  //     this.$router.push('marketPrice')
  //   }
  // },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
body{
  overflow: hidden;
}
.homepro{
  overflow: hidden;
}
.ho{
  background: #f9f5f0;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #f0f4f5;
  color:#4E7300;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.back-to-top i {
  font-size: 24px;
}

@media (min-width: 1800px) {
  .homepro{
    margin: 0 10%;
  }
  }
</style>
