<template>
                 <!-- this is the market price  date page in row left dist place, right table  -->
          <!-- this is the market price  date page in row left dist place, right table  -->
          <!-- this is the market price  date page in row left dist place, right table  -->
          <!-- this is the market price  date page in row left dist place, right table  -->
          <!-- this is the market price  date page in row left dist place, right table  -->
          <!-- this is the market price  date page in row left dist place, right table  -->


  <div class="full containers">

    <div class="banner row">
      <h1 class="heading">{{ translations.marketPrice }}</h1>
      <div class="loco col col-md-4 col-sm-12 col-xs-12 searchbox">
        <h5 class="catename">
          {{
            selectedLanguage === "en"
            ? $route.query.category_name
            : $route.query.category_tn
          }}
        </h5>
        <div class="dist" v-if="markets.length > 0">
          <h6>{{ translations.selectdist }}</h6>
          <select class="selec" v-model="selectedMarket" searchable>
            <option v-for="market in markets" :key="market.market_id" :value="market.market_id" :selected="market === markets[0]">
              {{ selectedLanguage === "en" ? market.market_name : market.tn_name }}
            </option>
          </select>
          
        </div>
        <div v-else>
          <p>No markets available</p>
          <!-- You can add more specific feedback based on your use case -->
        </div>

        <div class="town">
          <h6>{{ translations.selecttown }}</h6>
          <select class="selec" v-model="selectedPlace">
            <option value="0">{{ translations.all }}</option>
            <option v-for="place in placeData" :key="place.market_place_id" :value="place.market_place_id">
              {{ selectedLanguage === "en" ? place.place : place.tn_place }}
            </option>
          </select>
        </div>
      </div>

      <!-- Display market price data here -->
      <div class="listdate col col-md-8 col-sm-12 col-xs-12">
        <div class="colourmaker">
          <div v-if="isLoadingData" class="loading-spinner">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div v-else-if="marketPriceData.Dates.length > 0" class="boxdate">
            <h5 class="catename">{{ translations.listdate }}</h5>

            <div v-for="dateItem in marketPriceData.Dates" :key="dateItem.created" class="date"
              @click="redirectToPriceSublist(dateItem)">
              <h6 class="marketname">
                <span class="material-symbols-outlined"> location_on </span>
                {{
                  selectedLanguage === "en" ? dateItem.place : dateItem.tn_place
                }}
              </h6>
              <h6 class="created">
                <span class="material-symbols-outlined">calendar_month</span>
                {{ dateItem.created }}
              </h6>
              <!-- ... Display other content related to the date item ... -->
              {{ console.log("Data available:", marketPriceData.Dates.length) }}
            </div>
          </div>

          <div v-else class="nodata">
            <img src="../assets/img/nodata.png" loading="lazy" alt="nodata" />
            {{ console.log("No data available") }}
          </div>
        </div>
      </div>

      <div v-if="marketPriceData.Dates.length > 0" class="pagination">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" @click="prevPage" :disabled="currentPage === 1">
                <span aria-hidden="true" class="text-custom">&laquo;</span>
              </button>
            </li>
            <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }">
              <button class="page-link" @click="goToPage(page)" :class="{
                'bg-custom-active': page === currentPage,
                'text-custom': page !== currentPage,
              }">
                {{ page }}
              </button>
            </li>
            <li class="page-item">
              <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages">
                <span aria-hidden="true" class="text-custom">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { en, ta } from "@/translations";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      selectedMarket: this.$route.query.selectedMarket || "1",
      markets: [],
      selectedPlace: this.$route.query.selectedPlace || "0",
      placeData: [],
      marketPriceData: { Dates: [] },
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 10,
      isLoadingData: true,
      isInitialLoad: true, // Flag to determine initial load

    };
  },
  created() {
    const storedPageNumber = localStorage.getItem("currentPageNumber");
    if (storedPageNumber) {
      this.currentPage = parseInt(storedPageNumber);
      localStorage.removeItem("currentPageNumber"); // Remove the stored page number
    }

    this.fetchMarketData();
  },

  computed: {
    visiblePages() {
      const numPagesToShow = 5; // Adjust this value to determine how many pages to show
      const halfNumPages = Math.floor(numPagesToShow / 2);
      const firstVisiblePage = Math.max(1, this.currentPage - halfNumPages);
      const lastVisiblePage = Math.min(
        this.totalPages,
        this.currentPage + halfNumPages
      );
      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    },

    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  watch: {
    selectedMarket: {
    handler(newMarketId) {
      this.currentPage = 1; // Reset the current page to 1 when the market changes
      this.$router.replace({
        query: { ...this.$route.query, selectedMarket: newMarketId },
      });
      this.fetchPlaceData();
      this.fetchMarketPriceData();

      // Set selectedPlace to default value when selectedMarket changes, not on initial load
      if (!this.isInitialLoad) {
        this.selectedPlace = "0"; // Set the default value
      } else {
        this.isInitialLoad = false; // Set the flag to false after the initial load
      }
    },
    immediate: true,
  },
    selectedPlace: {
      handler(newPlaceId) {
        this.currentPage = 1; // Reset the current page to 1 when the place changes
        this.$router.replace({
          query: { ...this.$route.query, selectedPlace: newPlaceId },
        });
        this.fetchMarketPriceData();
      },
      immediate: true,
    },
  },

  methods: {
    redirectToPriceSublist(dateItem) {
      const selectedMarket = this.markets.find(
        (market) => market.market_id === this.selectedMarketId
      );

      const queryParams = {
        categoryId: this.$route.query.category_id,
        categoryName: this.$route.query.category_name,
        categorytn: this.$route.query.category_tn,
        marketId: this.selectedMarket,
        marketplaceId: this.selectedPlace,
        created: dateItem.created,
        place: dateItem.place,

        marketName: selectedMarket ? selectedMarket.market_name : "",
        selectProduct: this.selectProduct,
      };

      this.$router.push({
        path: "/pricesublist",
        query: queryParams,
      });
    },
    async fetchMarketData() {
      try {
        const category_id = this.$route.query.category_id;
        const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=market&categoryid=${category_id}`;

        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.status === "success") {
          this.markets = data.data;
        } else {
          console.error("API response error:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    },
    async fetchPlaceData() {
      if (this.selectedMarket) {
        try {
          const category_id = this.$route.query.category_id;
          const category = this.selectedMarket;
          // console.log(category);
          const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=market&categoryid=${category_id}&marketid=${category}`;
          const response = await fetch(apiUrl);

          const data = await response.json();
          if (data.status === "success") {
            this.placeData = data.data;
          } else {
            console.error("API response error:", data.msg);
          }
        } catch (error) {
          console.error("Error fetching place data:", error);
        }
      } else {
        this.placeData = []; // Reset placeData if no market is selected
        this.selectedPlace = ""; // Reset selectedPlace
      }
    },

    async fetchMarketPriceData() {
      this.isLoadingData = true; // Set loading state to true

      if (
        this.selectedMarket &&
        this.selectedPlace &&
        this.$route.query.category_id
      ) {
        try {
          const response = await fetch(
            `https://vaiyal-app.herokuapp.com/getmarketprice?page=${this.currentPage}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                marketid: this.selectedMarket,
                categoryid: this.$route.query.category_id,
                marketplaceid: this.selectedPlace,
              }),
            }
          );

          const data = await response.json();
          if (data.status === "success") {
            this.marketPriceData = data.data; // Update with fetched data
            this.totalPages = data.pages; // Update total pages based on the response
            this.itemsPerPage = data.itemsPerPage; // Update items per page based on the response
          } else {
            console.error("API response error:", data.msg);
            this.marketPriceData.Dates = []; // Reset marketPriceData if API response indicates no data
          }
        } catch (error) {
          console.error("Error fetching market price data:", error);
          this.marketPriceData.Dates = []; // Reset marketPriceData if an error occurs
        }
      } else {
        this.marketPriceData.Dates = []; // Reset marketPriceData if any parameter is missing
      }
      this.isLoadingData = false; // Set loading state to false
    },
    prevPage() {
      this.goToPage(this.currentPage - 1);
    },

    nextPage() {
      this.goToPage(this.currentPage + 1);
    },

    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        // Store the current page number in localStorage
        localStorage.setItem("currentPageNumber", pageNumber);

        this.currentPage = pageNumber;
        this.fetchMarketPriceData();

        // Scroll to the listdate section
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
        scrollToTop();
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

.full {
  font-family: "Raleway", sans-serif;
  margin: 0px 20px;
}

.banner {
  margin: 30px 0px;
}

.heading {
  margin: 10px 0px 0px 0px;
  text-align: center;
  color: #606060;
}

.listdate {
  padding: 30px 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
}

.listdate h5 {
  margin-bottom: 30px;
}

.catename {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 128, 0, 0.9);
  margin: 20px 0px;
  font-family: "Merriweather", serif;
}

.loco {
  padding: 30px 20px;
  height: 100%;
  border-radius: 6px;
}

.searchbox h6 {
  font-size: 14px;
  color: rgba(70, 67, 67, 0.8);
}

.searchbox select {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  color: rgba(70, 67, 67, 0.8);
  border: 0.5px solid rgba(163, 159, 159, 0.8);
}

.dist,
.town {
  margin: 20px 0px;
}


.dist select option:hover,
.town select option:hover {
  background-color: rgba(0, 128, 0, 0.9);
  color: #ffffff;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 10px 0px;
  padding: 7px 15px 0px 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-transform: capitalize;
}

.date h6 {
  font-size: 16px;
}

.created {
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  height: 300px;
}

.nodata img {
  margin-top: 50px;
  width: 400px;
}

.colourmaker .date {
  position: relative;
}

.colourmaker .date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(odd)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);
  z-index: -1;
}

.colourmaker .date:nth-child(even)::before {
  background-color: rgba(145, 155, 5, 0.9);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  z-index: -1;
}

.material-symbols-outlined {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9);
  padding-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination a {
  display: block;
  font-size: 12px;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.5s ease;
}

.pagination a:hover {
  background-color: rgba(0, 128, 0, 0.7);
}

.pagination li.active a {
  background-color: rgba(0, 128, 0, 0.9);
  color: #fff;
  border-radius: 4px;
}

.pagination li.disabled a {
  pointer-events: none;
  opacity: 0.6;
}

.bg-custom-active {
  background-color: green;
  color: white;
  border-radius: 4px;
}

.text-custom {
  color: green;
}

select.selec {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../assets/img/Icons/arrow_drop.png");
  background-size: 26px 26px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding: 8px 30px 8px 8px;
  border: 1px solid #ccc;
}

select.selec:focus {
  outline: none;
  border-color: #007bff;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: green;
}

.loading-spinner i {
  font-size: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .listdate {
    padding: 0px 20px;
  }

  .full {
    margin: 0px;
  }
}

@media (min-width: 322px) and (max-width: 575px) {

  .banner {
    display: block;
  }

  .full {
    margin: 0px;
  }
}

@media (max-width: 375px) {
  .full {
    margin: 0px;
  }

  .boxdate h6 {
    font-size: 12px;
  }

  .material-symbols-outlined {
    font-size: 12px;
  }

  .searchbox h6 {
    font-size: 10px;
    color: rgba(70, 67, 67, 0.8);
  }

  .catename {
    font-size: 16px;
  }
}
</style>
